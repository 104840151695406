import React, { useEffect, useState } from "react";
import { formatTableFirstColumn, get } from "../../helper";
import { Table } from "antd";

const StudentTimetablePage = ({ info }) => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const dayTranslations = {
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarşamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
    sunday: "Pazar",
  };

  const gunler = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  const bugun = new Date().getDay();
  const bugununTurkceAdi = gunler[bugun];

  const GetStudentsScheduleArray = async () => {
    const url =
      info === undefined
        ? `teacher/schedule`
        : info?.length === 1
        ? `schedule/${info?.at(0).class_id?.id}/${info?.at(0).section_id?.id}`
        : `schedule/${info?.class_id?.id}/${info?.section_id?.id}`;
    const result = await get(url);
    const schedule = info === undefined ? result.data : result.data.schedule;
    const columnArray = formatTableFirstColumn(Object.keys(schedule));
    columnArray.forEach((item) => {
      if (dayTranslations[item.key]) {
        item.title = dayTranslations[item.key];
      }
    });
    columnArray.forEach((column) => {
      if (column.title === bugununTurkceAdi) {
        column.title = (
          <span className="selectedDayInTable">{bugununTurkceAdi}</span>
        );
        column.className = "green-background";
      }
    });
    columnArray.unshift({
      title: "Saat / Gün",
      dataIndex: "timeRange",
      key: "timeRange",
      editable: true,
      width: "15%",
    });
    columnArray.map((item) => (item.align = "center"));
    setColumns(columnArray);
    const commonTimes = {};
    for (const day in schedule) {
      schedule[day].forEach((lesson) => {
        const timeRange = `${lesson.start_time} - ${lesson.finish_time}`;
        if (!commonTimes[timeRange]) {
          commonTimes[timeRange] = { timeRange };
        }
        commonTimes[timeRange][day] =
          info === undefined
            ? `${lesson?.class_id?.name} ${lesson?.section_id?.name} / ${lesson?.lesson_name}`
            : lesson?.lesson_id?.name;
      });
    }
    const dataSourceOne = [];
    for (const timeRange in commonTimes) {
      const result = commonTimes[timeRange];
      dataSourceOne.push(result);
    }
    dataSourceOne.map((item, index) => (item.key = index));
    dataSourceOne.sort((a, b) => {
      const timeA = parseInt(a.timeRange.split(" - ")[0].split(":")[0]);
      const timeB = parseInt(b.timeRange.split(" - ")[0].split(":")[0]);
      return timeA - timeB;
    });
    setDataSource(dataSourceOne);
  };

  useEffect(() => {
    GetStudentsScheduleArray();
  }, []);

  return (
    <Table
      bordered
      className="mt-20"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
    />
  );
};

export default StudentTimetablePage;
