import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Tag } from "antd";
import { GetUserData } from "../../helper";
import { columns } from "./Constant";
import { formatDate } from "../../helper/FormatDate";

const StudentRollCallPage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [rollCallInfo, setRollCallInfo] = useState({});

  const getUser = async () => {
    const result = await GetUserData();
    if (result.data.role === "Parent") {
      const raporluSayisi = result.data.children[0].absences.filter(function (
        item
      ) {
        return item.type === "Raporlu";
      }).length;
      const izinliSayisi = result.data.children[0].absences.filter(function (
        item
      ) {
        return item.type === "İzinli";
      }).length;
      const devamsızSayisi = result.data.children[0].absences.filter(function (
        item
      ) {
        return item.type === "Devamsız";
      }).length;
      setRollCallInfo({
        raporlu: raporluSayisi,
        izinli: izinliSayisi,
        devamsız: devamsızSayisi,
      });
      result.data.children[0].absences.map((item, i) => [
        (item.key = i),
        (item.date = item.date.split("T")[0]),
      ]);
      setDataSource(result.data.children[0].absences);
    } else {
      result.data.absences.map((item, i) => [
        (item.key = i),
        (item.date = item.date.split("T")[0]),
      ]);
      result.data.absences.map((item) => (item.date = formatDate(item.date)));
      setDataSource(result.data.absences);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col span={3} />
        <Col span={6}>
          <Card
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
            }}
          >
            <Row>
              <Col className="mt-10" span={12}>
                Devamsızlık
              </Col>
              <Col span={4} push={6}>
                <Tag
                  style={{
                    padding: "10px 20px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    fontSize: "20px",
                  }}
                  color="gold"
                >
                  {
                    (dataSource?.filter((item) => item.type === "Devamsız"))
                      .length
                  }
                </Tag>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
            }}>
            <Row>
              <Col className="mt-10" span={12}>
                Raporlu
              </Col>
              <Col span={4} push={6}>
                <Tag
                  style={{
                    padding: "10px 20px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    fontSize: "20px",
                  }}
                  color="purple"
                >
                  {
                    (dataSource?.filter((item) => item.type === "Raporlu"))
                      .length
                  }
                </Tag>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
            }}>
            <Row>
              <Col className="mt-10" span={12}>
                İzinli
              </Col>
              <Col span={4} push={6}>
                <Tag
                  style={{
                    padding: "10px 20px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    fontSize: "20px",
                  }}
                  color="green"
                >
                  {
                    (dataSource?.filter((item) => item.type === "İzinli"))
                      .length
                  }
                </Tag>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24} className="mt-30">
        <Col span={18} push={3}>
          <Table bordered dataSource={dataSource} columns={columns} />
        </Col>
      </Row>
    </>
  );
};

export default StudentRollCallPage;
