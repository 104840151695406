import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ModalFooter from "../../components/ModalFooter";
import { UploadOutlined } from "@ant-design/icons";
import { classContinue, studentContinue } from "../../services";
import { formatResultWith, get, post } from "../../helper";

import locale from "antd/es/date-picker/locale/tr_TR";
import "moment/locale/tr";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY/MM/DD";

const { RangePicker } = DatePicker;

const AddHomeworkAnnouncement = ({
  isAddModalOpen,
  setIsAddModalOpen,
  teacherLessonArray,
  getMessage,
  info,
  pathKey,
}) => {
  const [form] = Form.useForm();

  const props = {
    onRemove: (file) => {
      const newBase64Files = base64Files.filter((item) => item.file !== file);
      setBase64Files(newBase64Files);
    },
    beforeUpload: async (file) => {
      try {
        const base64 = await convertToBase64(file);
        setBase64Files((prevBase64Files) => [
          ...prevBase64Files,
          { file, base64 },
        ]);
      } catch (error) {
        console.error("Base64 conversion error:", error);
        message.error("Dosya base64'e çevrilemedi.");
        return false;
      }
      return false;
    },
    fileList: [],
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [sectionArray, setSectionArray] = useState([]);
  const [classArray, setClassArray] = useState([]);
  const [classSectionData, setClassSectionData] = useState([]);
  const [classValue, setClassValue] = useState();
  const [base64Files, setBase64Files] = useState([]);

  const onFinish = async (values) => {
    const object = { ...values };
    delete object.date;
    const first = new Date(values.date[0].$d);
    const second = new Date(values.date[1].$d);
    const arr = [];
    base64Files.map((item) => arr.push(item.base64));
    object.file = arr;
    object.type = pathKey === "odevler" ?  "homework" : "announcement";
    object.start_date = first.toISOString().split(".")[0];
    object.end_date = second.toISOString().split(".")[0];
    if (object.section_id === undefined) {
      const array = [];
      sectionArray.map((item) => array.push(item.value));
      object.section_id = array;
    } else {
      object.section_id = [object.section_id];
    }
    if (teacherLessonArray.length === 1)
      object.lesson_id = teacherLessonArray[0].value;
    const result = await post("message/", object);
    if (result.status === 200) {
      setIsAddModalOpen(false);
      message.success(result.data.detail);
      getMessage();
    } else message.error("Bir sorun oluştu");
  };

  const handleCancel = () => {
    form.resetFields();
    setIsAddModalOpen(false);
  };

  const getClassData = async () => {
    const result = await get(`${classContinue}`);
    setClassSectionData(result.data.detail);
    setClassArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const getStudentData = async (e) => {
    const result = await get(studentContinue, null, e);
    result.data.detail.map((item, index) => (item.key = index));
  };

  const onChangeClass = (e) => {
    setClassValue(e);
    form.setFieldsValue({ section_id: undefined });
    const sections = classSectionData.find((item) => item._id === e);
    const arr = [];
    sections.sections.map((item) =>
      arr.push({ label: item.name, value: item.id })
    );
    setSectionArray(arr);
  };

  const onChangeBranch = (e) => {
    getStudentData({
      page_size: 100,
      class_id: classValue,
      section_id: e,
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    form.resetFields();
    if (info !== undefined) {
      onChangeClass(info.class_id.id);
      const infoObject = {
        lesson_id: info.lesson_id.id,
        class_id: info.class_id.id,
        section_id: info.section_id.id,
        title: info.title,
        content: info.content,
      };
      form.setFieldsValue(infoObject);
    }
    getClassData();
  }, [info]);

  return (
    <Modal
      open={isAddModalOpen}
      onOk={() => setIsAddModalOpen(false)}
      onCancel={() => setIsAddModalOpen(false)}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
      centered
      width="60%"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          {teacherLessonArray.length !== 1 && (
            <Col span={8}>
              <Form.Item label="Ders" name="lesson_id">
                <Select showSearch allowClear options={teacherLessonArray} />
              </Form.Item>
            </Col>
          )}
          <Col span={teacherLessonArray.length === 1 ? 12 : 8}>
            <Form.Item label="Sınıf" name="class_id">
              <Select
                showSearch
                allowClear
                options={classArray}
                onChange={onChangeClass}
                optionFilterProp="children"
                filterOption={filterOption}
              />
            </Form.Item>
          </Col>
          <Col span={teacherLessonArray.length === 1 ? 12 : 8}>
            <Form.Item label="Şube" name="section_id">
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                options={sectionArray}
                onChange={onChangeBranch}
                filterOption={filterOption}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="title"
          label={pathKey === "odevler" ? "Ödev Başlığı" : "Duyuru Başlığı"}
        >
          <Input placeholder="Ödev başlığı giriniz"></Input>
        </Form.Item>
        <Form.Item
          name="content"
          label={
            pathKey === "odevler" ? "Ödev Açıklaması" : "Duyuru Açıklaması"
          }
        >
          <Input placeholder="Ödev açıklaması giriniz" />
        </Form.Item>
        <Form.Item label="Başlangıç - Bitiş Tarihi" name="date">
          <RangePicker
            style={{ width: "100%" }}
            defaultValue={
              info
                ? [
                    dayjs(info.date, dateFormat),
                    dayjs(info.end_date, dateFormat),
                  ]
                : undefined
            }
            locale={locale}
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="İçerik" name="file">
              <Upload {...props} multiple={true}>
                <Button
                  style={{ width: "700%", height: "150px" }}
                  icon={<UploadOutlined />}
                >
                  İçerik Seç
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddHomeworkAnnouncement;
