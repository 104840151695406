import { Card, Timeline } from "antd";
import React from "react";

const CalendarCard = () => {

  return (
    <div className="timelineDashboard">
    <Card style={{height:"252px"}} title={
      <div style={{ textAlign: 'left' }}>
        Günün Programı
      </div>
    }>
      <Timeline
        className="mt-30"
        items={[
          {
            color: "green",
            children: (
              <>
                {/* <p>8 Mart 2024</p> */}
                <p>Program Başlığı</p>
                <p>Program İçeriği</p>
              </>
            ),
          },
          {
            color: "blue",
            children: (
              <>
                {/* <p>8 Mart 2024</p> */}
                <p>8 / A</p>
                <p>Veli toplantısı</p>
              </>
            ),
          },
          {
            color: "red",
            children: (
              <>
                {/* <p>8 Mart 2024</p> */}
                <p>Öğretmenler toplantısı</p>
                <p>Öğretmenlerle sözleşme görüşmeleri</p>
              </>
            ),
          },
        ]}
      />
    </Card>
    </div>
  );
};

export default CalendarCard;
