import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, Modal, Select, message } from "antd";
import { ModalFooter } from "../../components";
import { formatResultMultipleName, get, post, put } from "../../helper";
import { studentContinue } from "../../services";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const dateFormat = "YYYY/MM/DD";

const AddOpinion = ({
  isModalOpen,
  setIsModalOpen,
  info,
  getUser,
  opinionType,
}) => {
  const [form] = Form.useForm();

  const [studentArray, setStudentArray] = useState([]);
  const [date, setDate] = useState("");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    delete values.date;
    values.date = date;
    if (values.date === "") {
      const splitDate = info.date.split("T")[0];
      values.date = `${splitDate}T13:53:34.070Z`;
    } else {
      const splitDate = values.date.split("T")[0];
      const newDate = `${splitDate.split("/")[0]}-${splitDate.split("/")[1]}-${
        splitDate.split("/")[2]
      }`;
      values.date = `${newDate}T13:53:34.070Z`;
    }
    const result = await (info
      ? put(`student/note/${info.id}`, values)
      : post("student/note", values));
    if (result.data.success)
      message.success(
        info ? "Görüş başarıyla düzenlendi" : "Görüş ekleme başarılı"
      );
    else message.error(result?.data?.detail);
    setIsModalOpen(false);
    getUser();
  };

  const onSearchStudentSelect = async (e) => {
    const inputLength = e.length;
    if (inputLength >= 3) {
      const result = await get(studentContinue, null, { keyword: e });
      setStudentArray(
        formatResultMultipleName(
          result.data.detail,
          "first_name",
          "last_name",
          "_id"
        )
      );
    } else
      setStudentArray([
        { label: "En az 3 harf girin", value: 1, disabled: true },
      ]);
  };

  const onChange = (date, dateString) => {
    setDate(`${dateString}T13:53:34.070Z`);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    form.resetFields();
    if (info) {
      setStudentArray([
        {
          value: info.student.id,
          label: `${info.student.first_name} ${info.student.last_name}`,
        },
      ]);
      form.setFieldsValue({
        student_id: info.student.id,
        talked_to: info.talked_to,
        note: info.note,
      });
    }
  }, [info]);

  return (
    <Modal
      title={info ? "Görüş & Görüşme Düzenle" : "Görüş & Görüşme Ekle"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
    >
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Öğrenci"
          name="student_id"
          rules={[
            {
              required: true,
              message: "Lütfen öğrenci seçiniz!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="children"
            options={studentArray}
            placeholder="Öğrencinin adını girin (en az 3 karakter)"
            filterOption={filterOption}
            onSearch={onSearchStudentSelect}
          />
        </Form.Item>
        {opinionType === "parentType" && (
          <Form.Item
            label="Veli Adı"
            name="talked_to"
            rules={[
              {
                required: true,
                message: "Lütfen veli adı giriniz!",
              },
            ]}
          >
            <Input placeholder="Görüşülen Veli Giriniz" />
          </Form.Item>
        )}
        <Form.Item
          label="Görüş İçeriği"
          name="note"
          rules={[
            {
              required: true,
              message: "Lütfen görüş içeriği giriniz!",
            },
          ]}
        >
          <Input placeholder="Görüş İçeriği Giriniz" />
        </Form.Item>
        <Form.Item
          label="Görüş Tarih"
          name="date"
          rules={[
            {
              required: true,
              message: "Lütfen görüş tarihi giriniz!",
            },
          ]}
        >
          <DatePicker
            defaultValue={
              info
                ? dayjs(
                    `${info?.date?.split("T")[0].split("-")[0]}/${
                      info?.date?.split("T")[0].split("-")[1]
                    }/${info?.date?.split("T")[0].split("-")[2]}`,
                    dateFormat
                  )
                : undefined
            }
            format={dateFormat}
            onChange={onChange}
            placeholder="Tarih Seçiniz"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOpinion;
