import { Card } from "antd";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  RecordsPage,
  RollCallPage,
  ClassUpdatePage,
  TimeTablePage,
  BillPage,
  PerformancePage,
  HomeworkPage,
  ProfilePage,
  HomePage,
  BillListPage,
  OpinionPage,
  CalendarPage,
  AssignmentPage,
  SihaReportPage,
  EfsoPage,
  ParagrafPiliPage,
} from "../pages";

const Content = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  return (
    <div
      style={{
        margin: "80px 16px",
        padding: path === "anaSayfa" ? 0 : 15,
        textAlign: "center",
        minHeight: 120,
      }}
    >
      <Card
        style={
          path === "anaSayfa"
            ? { backgroundColor: "#f5f5f5", border: "none" }
            : undefined
        }
      >
        <Routes>
          <Route path="/anaSayfa" element={<HomePage />}></Route>
          <Route path="/kayitIslemleri" element={<RecordsPage />}></Route>
          <Route path="/subeAtlatma" element={<ClassUpdatePage />}></Route>
          <Route path="/yoklamaIslemleri" element={<RollCallPage />}></Route>
          <Route path="/senetIslemleri" element={<BillPage />}></Route>
          <Route path="/senetListeleri" element={<BillListPage />}></Route>
          <Route path="/performans" element={<PerformancePage />}></Route>
          <Route path="/odemeTakip" element={<p>Ödeme Takip</p>}></Route>
          <Route path="/sihaRaporlama" element={<SihaReportPage />}></Route>
          <Route path="/gorusler" element={<OpinionPage />}></Route>
          <Route path="/takvim" element={<CalendarPage />}></Route>
          <Route path="/gorevlendirme" element={<AssignmentPage />}></Route>
          <Route path="/odevler" element={<HomeworkPage />}></Route>
          <Route path="/dersProgrami" element={<TimeTablePage />}></Route>
          <Route path="/duyurular" element={<HomeworkPage />}></Route>
          <Route path="/efsoKoc" element={<EfsoPage />}></Route>
          <Route path="/paragrafPili" element={<ParagrafPiliPage />}></Route>
          <Route path="/profil" element={<ProfilePage />}></Route>
        </Routes>
      </Card>
    </div>
  );
};

export default Content;
