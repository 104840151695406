import { loginInstance, instance } from "./Axios";

const loginPost = (endpoint, credentials = null) =>
  loginInstance.post(endpoint, credentials);

const post = (endpoint, credentials = null) =>
  instance.post(endpoint, credentials);

const put = (endpoint, credentials = null) =>
  instance.put(endpoint, credentials);

const putWithQuery = (endpoint, params = null) => {
  const url = params
    ? `${endpoint}?${new URLSearchParams(params).toString()}`
    : endpoint;
  return instance.put(url);
};

const delete_ = (endpoint, credentials = null) =>
  instance.delete(endpoint, credentials);

const get = (endpoint, credentials = null, params = {}) => {
  const url = credentials ? `${endpoint}?${credentials}` : endpoint;
  return instance.get(url, { params: params });
};

const getBlob = (endpoint, credentials = null, params = {}) => {
  const url = credentials ? `${endpoint}?${credentials}` : endpoint;
  return instance.get(url, { responseType: "blob", params: params });
};

export { get, post, put, delete_, loginPost, getBlob, putWithQuery };
