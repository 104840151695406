import { Col, Image, Row } from "antd";
import React from "react";
import photo from "../../assets/media/calendar.jpeg";

const Page = () => {
  return (
    <>
      <p>Kişisel Takvim Sayfası</p>
      <Row>
        <Col span={18}>
          <Image
            src={photo}
            style={{ width: "100%", height: "70vh" }}
            preview={false}
          />
        </Col>
        <Col span={6}>
          <p>
            Kullanıcıların kişisel ajandalarını oluşturarak günlük görevlerini
            takip etmelerini sağlayan bir otomasyon yazılımı modülüdür. Bu
            modül, her kullanıcının kendi hesabına giriş yaparak kişisel görev
            listelerini oluşturmasına, düzenlemesine ve yönetmesine olanak
            tanır.
          </p>
          <p>
            Kullanıcılar, yapılacak işleri ekleyebilir, önceliklerini
            belirleyebilir, tamamladıkları görevleri işaretleyebilir ve
            gerektiğinde görevleri güncelleyebilir veya silerek ajandalarını
            güncel tutabilirler.
          </p>
          <p>
            Ayrıca, modül genellikle kullanıcılara hatırlatmalar ve bildirimler
            göndererek önemli tarihleri veya son teslim tarihlerini takip
            etmelerine yardımcı olur. Bu sayede, kullanıcılar günlük iş
            akışlarını daha organize bir şekilde yönetebilir ve işlerini verimli
            bir şekilde tamamlayabilirler.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Page;
