import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Space, message } from "antd";
import { GetUserData } from "../helper";
import { useNavigate } from "react-router-dom";
import { UserOutlined, DownOutlined } from "@ant-design/icons";

const Profile = ({ setLoginAvailable }) => {
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  const items = [
    {
      label: "Profil Ayarları",
      key: "0",
      onClick: () => navigate("/profil"),
    },
    {
      type: "divider",
    },
    {
      label: "Çıkış Yap",
      key: "1",
      onClick: () => [
        setLoginAvailable(true),
        navigate("/"),
        message.error("Sistemden Çıkış Yapıldı"),
      ],
    },
  ];

  const getUser = async () => {
    const result = await GetUserData();
    setUserInfo(result.data);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Row gutter={24} className="profileRightArea">
        <Col xs={3} md={4} lg={4}>
          <UserOutlined className="profileIcon" />
        </Col>
        <Col xs={19} md={20} lg={20}>
          <Row>
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space className="profileUserName">
                  {`${userInfo?.first_name} ${userInfo?.last_name}`}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Row>
          <Row>
            <div className="profileSchoolName">
              {userInfo?.school_info?.SchoolName}
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
