import React, { useEffect, useState } from "react";
import { Card, Col, Form, Image, Row, Select, Spin } from "antd";
import { GetUserData, formatResultMultipleName, get } from "../../helper";
import manPhoto from "../../assets/media/man.png";
import { studentContinue } from "../../services";
import howTo from "../../assets/media/howTo.png";
import SplineChart from "./Chart";

const Performance = () => {
  const [form] = Form.useForm();

  const [type, setType] = useState(undefined);
  const [studentArray, setStudentArray] = useState([]);
  const [studentInfo, setStudentInfo] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState(false);
  const [grapData, setGrapData] = useState([]);

  const getUser = async () => {
    const result = await GetUserData();
    if (result.data.role === "Parent" && result.data.children.length > 0) {
      getData(result.data.children[0].id);
    }
    setType(result.data.role);
    setLoading(false);
  };

  const getData = async (id) => {
    get(`payment/performance/${id}`)
      .then((response) => {
        if (response.data.success) {
          if (response.data.detail.length !== 0) {
            setGrapData(response.data.detail);
            setView(true);
          } else setView(false);
        } else setView(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setView(false);
        } else setView(true);
      });
  };

  const onSearchStudentSelect = async (e) => {
    const inputLength = e.length;
    if (inputLength >= 3) {
      const result = await get(studentContinue, null, { keyword: e });
      setStudentArray(
        formatResultMultipleName(
          result.data.detail,
          "first_name",
          "last_name",
          "_id"
        )
      );
    } else
      setStudentArray([
        { label: "En az 3 harf girin", value: 1, disabled: true },
      ]);
  };

  const onChangeStudentName = async (e) => {
    if (e !== undefined) {
      const result = await get(`${studentContinue}${e}`);
      setStudentInfo(result.data);
      getData(result.data._id);
    } else setStudentInfo(undefined);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {loading ? (
        <Spin></Spin>
      ) : type === "Genel Personel" ? (
        <Form form={form} name="basic">
          <Row gutter={24} className="mt-20">
            <Col span={8}>
              <Card className="boxShadow">
                <Row gutter={24} className="mt-30">
                  <Col span={24}>
                    <Form.Item
                      label="Öğrenci"
                      name="student_id"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen öğrenci seçiniz!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        options={studentArray}
                        placeholder="Öğrencinin adını girin (en az 3 karakter)"
                        filterOption={filterOption}
                        onSearch={onSearchStudentSelect}
                        onChange={onChangeStudentName}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={16}>
              {studentInfo === undefined ? (
                <Row className="mt-30">
                  <Col span={6} className="mt-30">
                    <Image src={howTo} preview={false} />
                  </Col>
                  <Col span={18}>
                    <h1>
                      <span style={{ borderBottom: "2px solid green" }}>
                        Senet {""}
                      </span>
                      Performansı Modülüne Hoş Geldiniz!
                    </h1>
                    <p>
                      Öğrenci adını girdikten sorna, öğrencinin veli ve kendi
                      bilgilerini görebileceksiniz. Herhangi bir sorunuz veya
                      yardıma ihtiyacınız olursa, lütfen bize ulaşın
                    </p>
                  </Col>
                </Row>
              ) : (
                <Col span={24}>
                  <Card
                    className="boxShadow mt-30"
                    style={{
                      background: "linear-gradient(45deg, #B675F3, #7D36C0)",
                      color: "white",
                    }}
                  >
                    <Row gutter={24}>
                      <Col span={4}>
                        <Image
                          style={{ borderRadius: "10px" }}
                          src={manPhoto}
                          alt="Profile"
                          width={100}
                          height={100}
                          preview={false}
                        />
                      </Col>
                      <Col span={9} push={2}>
                        <Row className="mt-10">
                          {`${studentInfo?.parent?.first_name} ${studentInfo?.parent?.last_name}`}
                        </Row>
                        <Row className="mt-10">{`${studentInfo?.parent?.tckn}`}</Row>
                        <Row className="mt-10">{`${studentInfo?.parent?.phone}`}</Row>
                      </Col>
                      <Col
                        span={2}
                        style={{
                          fontSize: "90px",
                          textAlign: "center",
                          marginTop: "-10px",
                        }}
                      >
                        <span
                          style={{
                            borderRight: "2px solid white",
                            padding: "0 10px",
                          }}
                        />
                      </Col>

                      <Col span={9} push={2}>
                        <Row>{`${studentInfo.first_name} ${studentInfo.last_name}`}</Row>
                        <Row>{`${studentInfo.class_id?.name} / ${studentInfo.section_id?.name}`}</Row>
                        <Row>{`${studentInfo.student_no}`}</Row>
                        <Row>{`${studentInfo.tckn}`}</Row>
                        <Row>{`${studentInfo.phone}`}</Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )}
            </Col>
          </Row>
        </Form>
      ) : undefined}
      {view && (
        <>
          <Row gutter={24} className="mt-20">
            <Col span={24}>
              <Row gutter={24}>
                <Col span={24}>
                  <SplineChart data={grapData} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24} className="mt-20">
            <Col
              span={12}
              push={6}
              style={{ fontWeight: 700 }}
            >{`Güncel Başarı Oranınız => ${
              grapData[grapData.length - 1].score
            }`}</Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Performance;
