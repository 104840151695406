import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfView = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages._pdfInfo.numPages) setPageNumber(pageNumber + 1);
  };

  const prevPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  useEffect(() => {}, [pageNumber]);

  return (
    // <iframe
    //   style={{ marginTop: "20px" }}
    //   src={`https://bucket.enesdogan.dev/%C3%BCniversite%20haz%C4%B1rl%C4%B1k_planlama%20defteri_bask%C4%B1.pdf`}
    //   width="90%"
    //   height="600"
    // />
    <div className="wrap">
      <div className="controls">
        <button onClick={prevPage} disabled={pageNumber === 1} style={{ backgroundColor: "#7365FF", color: "white", cursor:"pointer" }}>
          Önceki
        </button>
        <button onClick={nextPage} disabled={pageNumber === numPages} style={{ backgroundColor: "#7365FF", color: "white", cursor:"pointer" }}>
          Sonraki
        </button>
      </div>
      <Card style={{height:"780px", overflow:"hidden", border:"none"}}>
        <Document
          file={`https://bucket.enesdogan.dev/%C3%BCniversite%20haz%C4%B1rl%C4%B1k_planlama%20defteri_bask%C4%B1.pdf`}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={(e) => e.preventDefault()}
          className="pdf-container"
        >
          <Page
            pageNumber={pageNumber}
            width={600}
            scale={0.9} // Ölçekleme faktörünü belirtin
          />
        </Document>
      </Card>
    </div>
  );
};

export default PdfView;
