import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { formatResultWith, formatResultWithout, get } from "../../../helper";
import { classContinue, sectionContinue } from "../../../services";

const Filter = ({ getData, setQueryDownload }) => {
  const [form] = Form.useForm();

  const [sectionArray, setSectionArray] = useState([]);
  const [classArray, setClassArray] = useState([]);

  const class_id = Form.useWatch("class_id", form);
  const section_id = Form.useWatch("section_id", form);
  const studentNo = Form.useWatch("studentNo", form);

  const getSectionData = async () => {
    const result = await get(sectionContinue);
    setSectionArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const getClassData = async () => {
    const result = await get(`${classContinue}`);
    setClassArray(
      formatResultWith(result.data.detail, "name", "_id").sort(function (a, b) {
        return a.value - b.value;
      })
    );
  };

  useEffect(() => {
    getClassData();
    getSectionData();
  }, []);

  useEffect(() => {
    const object = {};
    const arr = [class_id, section_id, studentNo];
    arr.map((item, i) =>
      item && item !== ""
        ? i === 0
          ? (object.class_id = item)
          : i === 1
          ? (object.section_id = item)
          : (object.keyword = item)
        : undefined
    );
    setQueryDownload(object);
  }, [class_id, section_id, studentNo]);

  return (
    <div className="studentRecordsPage">
    <Form
      form={form}
      name="basic"
    >
      <Row gutter={24} style={{ marginTop: "30px", marginBottom: "-20px" }}>
        <Col span={8}>
          <Form.Item label="Sınıf" name="class_id">
            <Select
              style={{ textAlign: "left" }}
              showSearch
              allowClear
              placeholder="Sınıf seçiniz"
              optionFilterProp="children"
              options={classArray}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Şube" name="section_id">
            <Select
              showSearch
              placeholder="Şube seçiniz"
              allowClear
              optionFilterProp="children" 
              options={sectionArray}
              style={{ textAlign: "left" }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Öğrenci No" name="studentNo">
            <Input style={{borderRadius:"20px"}} placeholder="Öğrenci No giriniz" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    </div>
  );
};

export default Filter;
