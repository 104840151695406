import { Button, Col, Form, Input, Row, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { formatResultWith, get, post } from "../../helper";
import { PlusOutlined } from "@ant-design/icons";

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  handleChangeSelect(value) {
    const { dataIndex, record } = this.props;
    record[dataIndex] = value;
  }

  handleChangeInput(value) {
    const { dataIndex, record } = this.props;
    record[dataIndex] = value.target.value;
  }

  render() {
    const { editable, dataIndex, title, record, ...restProps } = this.props;

    return (
      <td {...restProps}>
        {editable ? (
          title === "Saat / Gün" ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent:"center" }}>
            <Input
              style={{ width: "100px", fontSize: "10px" }}
              defaultValue={record.timeRange}
              onChange={(value) => this.handleChangeInput(value)}
            />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", justifyContent:"center" }}>
            <Select
              style={{ minWidth: "120px" }}
              placeholder="Seçiniz"
              defaultValue={record[dataIndex]}
              onChange={(value) => this.handleChangeSelect(value)}
              options={this.props.options} // Use the options directly from the prop
            />
          </div>
          )
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const TableComp = ({ dataSource, setDataSource, columns, values, type }) => {
  const [lessonData, setLessonData] = useState([]);

  const gunler = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  const bugun = new Date().getDay();
  const bugununTurkceAdi = gunler[bugun];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get("lesson/");
        const formattedData = formatResultWith(
          result.data.detail,
          "name",
          "_id"
        ).sort(function (a, b) {
          return a.value - b.value;
        });
        setLessonData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columnsEditable = columns.map((col) => {
    if (col.title === bugununTurkceAdi) {
      col.title = (
        <span className="selectedDayInTable">{bugununTurkceAdi}</span>
      );
      col.className = "green-background";
    }
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: col.editable,
        options: col.title === "Saat / Gün" ? null : lessonData,
      }),
    };
  });

  const handleSubmit = async () => {
    const output = transformData(dataSource);
    const object = {
      ...values,
      schedule: output,
    };
    const result = await post("schedule/create", object);
    if (result.data.success)
      message.success("Ders Programı başarıyla kaydedildi");
    else message.error("Başarısız");
  };

  const transformData = (data) => {
    const result = {};
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    days.forEach((day) => {
      const daySchedule = [];
      data.forEach((item) => {
        const timeSlot = item[day];
        if (timeSlot) {
          const separators = [" -", "- ", "-", " - "];
          let [startTime, endTime] = [];
          for (const separator of separators) {
            if (item.timeRange.includes(separator)) {
              [startTime, endTime] = item.timeRange.split(separator);
              break;
            }
          }
          const lessonInfo = {
            start_time: startTime,
            finish_time: endTime,
            lesson_id: timeSlot,
          };
          daySchedule.push(lessonInfo);
        }
      });
      result[day] = daySchedule;
    });

    return result;
  };

  const addRow = () => {
    let newRow = {
      key: dataSource.length.toString(),
    };
    columns.forEach((item) => (newRow[item.dataIndex] = ""));
    setDataSource([...dataSource, newRow]);
  };

  useEffect(() => {}, [dataSource]);

  return (
    <>
      {dataSource.length > 0 && (
        <>
          <Row justify="space-between">
            <Col />
            <Col>
              <Button
                style={{ backgroundColor: "#7365FF", color: "white", marginRight:"5px" }}
                icon={<PlusOutlined />}
                onClick={addRow}
              >
                Yeni Saat / Gün Ekle
              </Button>
              <Button
                style={{ backgroundColor: "#7365FF", color: "white" }}
                onClick={handleSubmit}
              >
                Kaydet
              </Button>
            </Col>
          </Row>
          <Row gutter={24} className="timeTableComp">
            <Col span={24}>
              <Table
                className="mt-20"
                components={{
                  body: {
                    row: EditableRow,
                    cell: EditableCell,
                  },
                }}
                dataSource={dataSource}
                columns={columnsEditable}
                pagination={false}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TableComp;
