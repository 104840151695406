import { Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { GetUserData, formatResultWith, formatTableFirstColumn, get } from "../../helper";
import { classContinue } from "../../services";

const Filter = ({ setDataSource, setColumns, setValues }) => {
  const [form] = Form.useForm();

  const [userData, setUserData] = useState({});
  const [view, setView] = useState(false);
  const [classArray, setClassArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);
  const [classSectionData, setClassSectionData] = useState([]);

  const getData = async () => {
    const result = await get(`${classContinue}`);
    setClassSectionData(result.data.detail);
    setClassArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const onChangeClass = (e) => {
    form.setFieldsValue({ section_id: undefined });
    const sections = classSectionData.find((item) => item._id === e);
    const arr = [];
    sections.sections.map((item) =>
      arr.push({ label: item.name, value: item.id })
    );
    setSectionArray(arr);
  };

  const onChangeBranch = async () => {
    const values = form.getFieldsValue();
    setValues(values);
    const result = await get(
      `schedule/${values.class_id}/${values.section_id}`
    );
    const schedule = result.data.schedule;
    const columnArray = formatTableFirstColumn(Object.keys(schedule));
    columnArray.map((item) =>
      item.key === "monday"
        ? (item.title = "Pazartesi")
        : item.key === "tuesday"
        ? (item.title = "Salı")
        : item.key === "wednesday"
        ? (item.title = "Çarşamba")
        : item.key === "thursday"
        ? (item.title = "Perşembe")
        : item.key === "friday"
        ? (item.title = "Cuma")
        : item.key === "saturday"
        ? (item.title = "Cumartesi")
        : item.key === "sunday"
        ? (item.title = "Pazar")
        : undefined
    );
    columnArray.unshift({
      title: "Saat / Gün",
      dataIndex: "timeRange",
      key: "timeRange",
      editable: true,
      width: "15%",
    });
    setColumns(columnArray);
    const commonTimes = {};
    for (const day in schedule) {
      schedule[day].forEach((lesson) => {
        const timeRange = `${lesson.start_time} - ${lesson.finish_time}`;
        if (!commonTimes[timeRange]) {
          commonTimes[timeRange] = { timeRange };
        }

        commonTimes[timeRange][day] = lesson.lesson_id.id;
      });
    }
    const dataSourceOne = [];
    for (const timeRange in commonTimes) {
      const result = commonTimes[timeRange];
      dataSourceOne.push(result);
    }
    dataSourceOne.sort((a, b) => {
      const timeA = parseInt(a.timeRange.split(" - ")[0].split(":")[0]);
      const timeB = parseInt(b.timeRange.split(" - ")[0].split(":")[0]);
      return timeA - timeB;
    });
    dataSourceOne.map((item, index) => (item.key = index));
    setDataSource(dataSourceOne);
  };

  useEffect(() => {
    getData();
  }, []);

  const getMe = async () => {
    const result = await GetUserData();
    setUserData(result.data);
    if (result.data.role === "Student") {
      setView(false);
      const object = {
        class_id: result.data.class_id.id,
        section_id: result.data.section_id.id,
      };
      onChangeBranch(object);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Sınıf" name="class_id">
              <Select
                showSearch
                allowClear
                options={classArray}
                onChange={onChangeClass}
                optionFilterProp="children"
                filterOption={filterOption}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Şube" name="section_id">
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                options={sectionArray}
                onChange={onChangeBranch}
                filterOption={filterOption}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
