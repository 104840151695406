import React, { useState } from "react";
import { Layout } from "antd";
import { Content } from ".";
import { FooterLayout } from ".";
import { HeaderLayout } from ".";
import { MenuLayout } from ".";
import { LoginPage } from "../pages";

const SiteGeneralLayout = () => {
  const [loginAvailable, setLoginAvailable] = useState(true);

  return (
    <>
      {loginAvailable ? (
        <LoginPage setLoginAvailable={setLoginAvailable} />
      ) : (
        <Layout>
          <HeaderLayout setLoginAvailable={setLoginAvailable}/>
          <Layout hasSider>
            <MenuLayout />
            <Layout>
              <Content />
              <FooterLayout />
            </Layout>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default SiteGeneralLayout;
