import React from "react";
import { Button } from "antd";
import {
  PlayCircleOutlined
} from "@ant-design/icons";

const HowToComponent = () => {
  return (
    <Button
      style={{
        backgroundColor: "#7366F9",
        color: "white",
        borderRadius: "5px",
      }}
      size={"large"}
      icon={<PlayCircleOutlined />}
      block
    >
      Nasıl Yapılır ?
    </Button>
  );
};

export default HowToComponent;
