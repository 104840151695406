import React, { useEffect, useState } from "react";
import { Spin, Tabs } from "antd";
import { items } from "./Constant";
import { GetUserData } from "../../helper";
import StudentRollCallPage from "./StudentRollCallPage";
import MultipleChildrenParent from "./MultipleChildrenParent";

const Page = () => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(undefined);
  const [viewTabs, setViewTabs] = useState(false);
  const [children, setChildren] = useState([]);

  const getUser = async () => {
    try {
      const result = await GetUserData();
      if (result?.data?.children?.length > 1) {
        setViewTabs(true);
        setChildren(result.data.children);
      }
      setType(result.data.role);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : type === "Student" || (type === "Parent" && !viewTabs) ? (
        <StudentRollCallPage />
      ) : type === "Parent" && viewTabs ? (
        <MultipleChildrenParent children={children} />
      ) : (
        <Tabs defaultActiveKey="1" type="card" items={items} centered />
      )}
    </>
  );
};

export default Page;
