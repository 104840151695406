import React from "react";
import { Button, Upload, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

const FileUpload = ({ type, getData }) => {

  const onChange = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      getData();
      message.success(`${info.file.name} başarıyla yüklendi`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} yüklenirken başarısız oldu`);
    }
  };

  return (
    <Upload
      action={`https://api.sihaotomasyon.com/${type}/excel/import`}
      onChange={onChange}
      withCredentials={true}
    >
      <Button
        style={{
          backgroundColor: "#7366F9",
          color: "white",
          borderRadius: "5px",
        }}
        size={"large"}
        icon={<CloudUploadOutlined />}
        block
      >
        Excel Yükle
      </Button>
    </Upload>
  );
};

export default FileUpload;