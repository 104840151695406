import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import TableComp from "./Table";
import { Spin } from "antd";
import { GetUserData } from "../../helper";
import TimetableWithoutEdit from "./TimetableWithoutEdit";

const Page = () => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [values, setValues] = useState();
  const [studentsInfo, setStudentsInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(undefined);

  const getUser = async () => {
    try {
      const result = await GetUserData();
      if (result.data.role === "Student") setStudentsInfo([result.data]);
      else setStudentsInfo(result?.data?.children);
      setType(result.data.role);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : type === "Genel Personel" ? (
        <>
          <Filter
            setDataSource={setDataSource}
            setColumns={setColumns}
            setValues={setValues}
          />
          <TableComp
            dataSource={dataSource}
            columns={columns}
            values={values}
            setDataSource={setDataSource}
            type={type}
          />
        </>
      ) : (
        <TimetableWithoutEdit type={type} studentsInfo={studentsInfo} />
      )}
    </>
  );
};

export default Page;
