import { Card } from "antd";
import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
} from "recharts";

const RollCallCard = () => {
  const data = [
    {
      name: "Sayısal 12 A",
      gelmeyen: 4000,
      gelen: 2400,
    },
    {
      name: "Sayısal 12 B",
      gelmeyen: 3000,
      gelen: 1398,
    },
    {
      name: "Sözel 12 C",
      gelmeyen: 2000,
      gelen: 9800,
    },
    {
      name: "Sözel 12 D",
      gelmeyen: 2780,
      gelen: 3908,
    },
  ];

  return (
    <div className="rollCallCardComp">
    <Card title={
      <div style={{ textAlign: 'left' }}>
        Günün Yoklamaları
      </div>
    }>
      <div style={{ width: "95%", height: 195 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical" // Bu satırı ekleyerek sağdan sola doğru gidişi sağlar
            width={500}
            height={200}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />{" "}
            <YAxis dataKey="name" type="category" />{" "}
            <Tooltip />
            <Legend />
            <Bar
              dataKey="gelen"
              fill="#002F49"
              shape={<Rectangle fill="#002F49" />}
            />
            <Bar
              dataKey="gelmeyen"
              fill="#EA5B0C"
              shape={<Rectangle fill="#EA5B0C" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
    </div>
  );
};

export default RollCallCard;
