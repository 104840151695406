import { Button, Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { formatResultWithout, get, post } from "../../../helper";
import {
  classContinue,
  sectionContinue,
  lessonContinue,
  grades,
  levels,
} from "../../../services";

const AddBranchLesson = ({
  isModalOpen1,
  setIsModalOpen1,
  sectionLessonType,
  onChangeGrade,
  getSectionData
}) => {
  const [form] = Form.useForm();

  const [levelArray, setLevelArray] = useState([]);
  const [gradeArray, setGradeArray] = useState([]);

  const handleOk = () => setIsModalOpen1(false);

  const handleCancel = () => setIsModalOpen1(false);

  const onFinish = async (values) => {
    const result = await post(
      sectionLessonType === "lesson" ? lessonContinue  : sectionContinue,
      values
    );
    if (result.status === 200) {
      message.success(
        sectionLessonType === "lesson"
          ? "Ders başarıyla eklendi"
          : "Şube başarıyla eklendi"
      );
      onChangeGrade();
      getSectionData();
    } else
      message.error(
        sectionLessonType === "lesson"
          ? "Ders eklenirken hata oluştu"
          : "Şube eklenirken hata oluştu"
      );
    setIsModalOpen1(false);
    form.resetFields();
  };

  const onChangeLevel = async (e) => {
    const result = await get(`${classContinue}${grades}`, null, {
      level: e,
    });
    setGradeArray(formatResultWithout(result.data));
  };

  const getLevelData = async () => {
    const result = await get(`${classContinue}${levels}`);
    setLevelArray(formatResultWithout(result.data));
  };

  useEffect(() => {
    getLevelData();
  }, []);

  return (
    <Modal
      title={sectionLessonType === "lesson" ? "Ders Ekle" : "Şube Ekle"}
      open={isModalOpen1}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button className="closeBtnModal">Kapat</Button>,
        <Button className="saveBtnModal" onClick={() => form.submit()}>
          Kaydet
        </Button>,
      ]}
    >
      <Form name="basic" form={form} onFinish={onFinish} autoComplete="off">
        <Form.Item name="name">
          <Input
            className="mt-10"
            placeholder={
              sectionLessonType === "lesson"
                ? "Ders Adı Giriniz..."
                : "Şube Adı Giriniz..."
            }
          />
        </Form.Item>
        {sectionLessonType === "lesson" && (
          <>
            {" "}
            <Form.Item name="level">
              <Select
                showSearch
                onChange={onChangeLevel}
                placeholder="Üst Düzey Seçiniz"
                optionFilterProp="children"
                options={levelArray}
              />
            </Form.Item>
            <Form.Item name="grade">
              <Select
                showSearch
                placeholder="Düzey Seçiniz"
                optionFilterProp="children"
                options={gradeArray}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddBranchLesson;
