import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Popconfirm,
  Progress,
  Row,
  Tag,
  message,
} from "antd";
import { delete_, putWithQuery } from "../../helper";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EmptyPage from "../../components/EmptyPage";
import { formatDate } from "../../helper/FormatDate";

const AssignmentCard = ({
  assignmentArray,
  getAssignments,
  type,
  setInfo,
  setIsModalOpen,
}) => {
  const onClickGetAssignment = async (item) => {
    const values = {
      status: "in_progress",
    };
    const result = await putWithQuery(`task/status/${item._id}`, values);
    if (result.data.success) message.success("Görev Alma başarılı");
    else message.error("Mesaj alma başarısız");
    getAssignments();
  };

  const onClickDoneAssignment = async (item) => {
    const values = {
      status: "done",
    };
    const result = await putWithQuery(`task/status/${item._id}`, values);
    getAssignments();
  };

  const onClickDelete = async (e) => {
    const result = await delete_(`task/${e}`);
    if (result.data.success) {
      message.success(result.data.detail);
      getAssignments();
    } else message.error(result.data.detail);
  };

  const getDayDifference = (endDateString) => {
    const today = new Date();
    const endDate = new Date(endDateString);
    today.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    const timeDifference = endDate.getTime() - today.getTime();
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return dayDifference;
};


  return (
    <>
      <Row gutter={[16, 16]} className="mt-20">
        {assignmentArray.length === 0 ? (
          <EmptyPage />
        ) : (
          assignmentArray.map((item) => (
            <Col key={item.id} md={24} lg={12}>
              <Card key={item.id}>
                <Row justify="space-between">
                  <Col />
                  <Col>
                    <Tag
                      color={
                        item.status === "given"
                          ? "red"
                          : item.status === "in_progress"
                          ? "yellow"
                          : "green"
                      }
                    >
                      {item.status === "given"
                        ? "Görev Atandı"
                        : item.status === "in_progress"
                        ? "Yapılıyor"
                        : "Tamamlandı"}
                    </Tag>
                    {type === "Genel Personel" && (
                      <>
                        {" "}
                        <Button
                          className="borderNone green boxShadowNone"
                          style={{ marginRight: "-15px" }}
                          onClick={() => [setIsModalOpen(true), setInfo(item)]}
                        >
                          <EditOutlined />
                        </Button>
                        <Popconfirm
                          title="Görev silme işlemi"
                          description="Görevi silmek istediğinizden emin misiniz ?"
                          onConfirm={() => onClickDelete(item._id)}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Button className="borderNone boxShadowNone">
                            <DeleteOutlined className="red" />
                          </Button>
                        </Popconfirm>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontWeight: 700 }}>{item.title}</Col>
                </Row>
                <Row>
                  <Col>
                    <Avatar
                      size="medium"
                      src="https://api.dicebear.com/7.x/miniavs/svg?seed=3"
                    />
                  </Col>
                  <Col className="mt-10" style={{ color: "gray" }}>
                    {`${item.teacher_id.first_name} ${item.teacher_id.last_name} ,`}
                  </Col>
                  <Col className="mt-10" style={{ color: "gray" }}>
                    {`${item?.teacher_id?.lesson_id?.at(0).name}`}
                  </Col>
                </Row>
                <Row className="mt-10" style={{ textAlign: "left" }}>
                  {item.content}
                </Row>
                <Row gutter={24} className="mt-10">
                  <Col span={12} align="left" style={{ color: "gray" }}>
                    Başlama Tarihi
                  </Col>
                  <Col span={2} style={{ color: "gray" }}>
                    :
                  </Col>
                  <Col span={8} style={{ color: "#579BFE" }}>
                    {formatDate(item.start_date)}
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12} align="left" style={{ color: "gray" }}>
                    Bitiş Tarihi
                  </Col>
                  <Col span={2} style={{ color: "gray" }}>
                    :
                  </Col>
                  <Col span={8} style={{ color: "#579BFE" }}>
                    {formatDate(item.end_date)}
                  </Col>
                </Row>
                <Row gutter={24} className="mt-10">
                  <Col span={24}>
                    {item.status !== "done" && (
                      <>
                        <Row justify="space-between">
                          <Col style={{ marginLeft: "5px" }}>
                            <span style={{ fontWeight: 700 }}>
                              {item.progress >= 100 ? "100" : item.progress}%
                            </span>
                            <span style={{ color: "gray" }}> tamamlandı</span>
                          </Col>
                          <Col
                            key={item._id}
                            style={{
                              fontWeight: 300,
                              color: "gray",
                              marginRight: "32px",
                            }}
                          >
                            {`${
                              getDayDifference(item.end_date) < 0
                                ? 0
                                : getDayDifference(item.end_date)
                            } gün kaldı`}
                          </Col>
                        </Row>
                        <Row gutter={24} style={{ marginTop: "-10px" }}>
                          <Col span={24}>
                            <Progress
                              strokeColor={
                                item.progress < 30
                                  ? "yellow"
                                  : item.progress < 70
                                  ? "orange"
                                  : "red"
                              }
                              percent={
                                item.status === "done" ? 100 : item.progress
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={16} push={4}>
                    {type === "Teacher" && (
                      <>
                        {item.status === "given" && (
                          <Button
                            icon={<CheckOutlined />}
                            onClick={() => onClickGetAssignment(item)}
                          >
                            Görevi Al
                          </Button>
                        )}
                        {item.status === "in_progress" && (
                          <Button
                            icon={<CheckOutlined />}
                            onClick={() => onClickDoneAssignment(item)}
                          >
                            Görevi Tamamla
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

export default AssignmentCard;
