import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

const NavigateButton = ({ url }) => {
  const navigate = useNavigate();
  return (
    <Button className="navigateBtn" onClick={() => navigate(url)} type="text">
      Tümünü Gör <ArrowRightOutlined size="large" style={{ marginLeft: "8px"}} />
    </Button>
  );
};

export default NavigateButton;
