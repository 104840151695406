import React from "react";
import { Card, Col, Row } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";
import phot from "../../../assets/media/ders-programı.png";

const TimeTable = ({ scheduleArray, type = "Teacher" }) => {
  const colorArr = ["blue", "green", "red", "yellow"];

  return (
    <div className="homePageTimeTable">
      <Card
        title={<div style={{ textAlign: "left" }}>Ders Programı</div>}
        style={{ height: type === "Teacher" ?  "460px" : "612px" }}
      >
        <div style={{position:"absolute", top:0, right:0, zIndex:1, opacity:0.2}}>
          <img src={phot} alt="ders programı" />
        </div>
        {scheduleArray?.map((item, i) => (
          <Card
            key={i}
            style={{
              borderRadius: "2px",
              borderLeft: `4px solid ${colorArr[i % colorArr.length]}`,
              zIndex:0
            }}
          >
            <Row gutter={24}>
              <Col span={9} style={{ marginTop: "5px" }}>
                {type === "Teacher" && (
                  <Row
                    style={{ fontSize: "10px" }}
                  >{`${item?.class_id?.name} ${item?.section_id?.name}`}</Row>
                )}
                <Row
                  style={{
                    fontSize: "10px",
                    marginTop: type === "Teacher" ? 0 : "5px",
                  }}
                >
                  {type === "Teacher"
                    ? item?.lesson?.lesson_id?.name
                    : item?.lesson_id?.name}
                </Row>
              </Col>
              <Col span={2} style={{ fontSize: "25px", color: "gray" }}>
                |
              </Col>
              <Col span={10} style={{ fontSize: "9px" }} className="mt-10">
                <ClockCircleFilled
                  style={{ color: "green", marginRight: "3px" }}
                />
                {type === "Teacher"
                  ? `${item?.lesson?.start_time} - ${item?.lesson?.finish_time}`
                  : `${item?.start_time} - ${item?.finish_time}`}
              </Col>
            </Row>
          </Card>
        ))}
      </Card>
    </div>
  );
};

export default TimeTable;
