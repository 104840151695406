import { Button, Card, Col, Popconfirm, Row, message } from "antd";
import React, { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import matIcon from "../../assets/media/matIcon.png";
import ViewHomework from "./ViewHomework";
import { delete_ } from "../../helper";

const HomeworkCard = ({
  type,
  homeworkArray,
  setIsAddModalOpen,
  setInfo,
  getMessage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHomework, setSelectedHomework] = useState();

  const onClickViewHomework = (item) => {
    setIsModalOpen(true);
    setSelectedHomework(item);
  };

  const deleteHomework = async (id) => {
    const result = await delete_(`message/${id}`);
    if (result.data.success) {
      getMessage();
      message.success("Ödev Başarıyla Silindi");
    } else message.error("Hata oluştu");
  };

  return (
    <div className="homeworkCardComp">
      <Row gutter={[16, 16]}>
        {homeworkArray.map((item) => (
          <Col key={item.id} sm={24} xl={12}>
            <Card
              title={
                <Row justify="space-between">
                  <Col>
                    <Row>
                      <Col>
                        <img
                          src={matIcon}
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                          }}
                        />
                      </Col>
                      <Col style={{ marginTop: "5px" }}>
                        <p
                          style={{
                            lineHeight: "16px",
                            fontSize: "12px",
                            color: "#7365FE",
                            margin: 0,
                          }}
                        >
                          {item.lesson_id.name}
                        </p>
                        <p
                          style={{
                            lineHeight: "16px",
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "gray",
                            margin: 0,
                          }}
                        >
                          {`${item.teacher_id.first_name} ${item.teacher_id.last_name}`}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {type === "Teacher" && (
                      <>
                        {" "}
                        <Button
                          icon={
                            <EditOutlined
                              style={{ color: "green", marginRight: "-10px" }}
                            />
                          }
                          style={{ border: "none", boxShadow: "none" }}
                          onClick={() => [
                            setInfo(item),
                            setIsAddModalOpen(true),
                          ]}
                        />
                        <Popconfirm
                          title="Ödev silme işlemi"
                          description="Ödevi silmek istediğinizden emin misiniz ?"
                          onConfirm={() => deleteHomework(item._id)}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Button
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            style={{ border: "none", boxShadow: "none" }}
                          />
                        </Popconfirm>
                      </>
                    )}

                    <Button
                      style={{
                        backgroundColor: "#7365FE",
                        color: "white",
                        marginTop: "5px",
                        padding: "2px 10px",
                        fontSize: "10px",
                      }}
                      onClick={() => onClickViewHomework(item)}
                    >
                      Ödevi Görüntüle
                    </Button>
                  </Col>
                </Row>
              }
            >
              <p style={{ textAlign: "left", marginLeft: "5px" }}>
                {item.title}
              </p>
            </Card>
          </Col>
        ))}
      </Row>
      <ViewHomework
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        selectedHomework={selectedHomework}
      />
    </div>
  );
};

export default HomeworkCard;
