import { Tag } from "antd";

export const columns = [
  {
    title: "Öğrenci No",
    dataIndex: "student_no",
    key: "student_no",
    align: "center",
  },
  {
    title: "İsim",
    dataIndex: "first_name",
    key: "first_name",
    align: "center",
  },
  {
    title: "Soyisim",
    dataIndex: "last_name",
    key: "last_name",
    align: "center",
  },
  {
    title: "Durum",
    dataIndex: "absence_status",
    key: "absence_status",
    align: "center",
    render: (record) => {
      if (record) {
        return (
          <Tag color={record === "Raporlu" ? "cyan" : "orange"} key={record}>
            {record}
          </Tag>
        );
      }
    },
  },
];
