import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tag, Timeline } from "antd";
import { GetUserData, get } from "../../helper";
import ForzaCard from "./Components/ForzaCard";
import ProfileCard from "./Components/ProfileCard";
import { NavigateButton } from "../../components";
import TimeTable from "./Components/TimeTable";
import announcementPng from "../../assets/media/announcement.png";
import PieChartComponent from "./Components/Chart";

const ParentHomePage = () => {
  const [scheduleArray, setScheduleArray] = useState([]);
  const [homeworkArray, setHomeworkArray] = useState([]);
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [childInfo, setChildInfo] = useState({});
  const [userInfo, setUserInfo] = useState(undefined);

  const getData = async () => {
    const result = await get("dashboard");
    setChildInfo(result.data.children[0]);
    setScheduleArray(result.data.children[0].schedule[0]);
    setHomeworkArray(result.data.children[0].homework);
    const arr = [];
    result.data.children[0].announcement.map((item, i) =>
      arr.push({
        color: i === 0 ? "orange" : i === 1 ? "blue" : "green",
        children: (
          <>
            <Row gutter={24} className="mt-20" style={{ lineHeight: "35px" }}>
              <Col span={12}>
                <Row>
                  <Tag
                    color="#F5F5F5"
                    style={{
                      color: "black",
                      borderRadius: "20px",
                      padding: "2px 10px",
                      fontWeight: 600,
                    }}
                  >
                    {item.title}
                  </Tag>
                </Row>
                <Row style={{ fontWeight: 800, marginLeft: "10px" }}>
                  {item.end_date.split("T")[0]}
                </Row>
              </Col>
              <Col
                span={12}
                style={{ marginTop: "2px", fontWeight: 450, color: "gray" }}
              >
                {item.date.split("T")[0]}
              </Col>
            </Row>
          </>
        ),
      })
    );
    setAnnouncementArray(arr);
  };

  const getUser = async () => {
    const result = await GetUserData();
    setUserInfo(result.data);
  };

  useEffect(() => {
    getData();
    getUser();
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col span={18}>
          <Row gutter={24}>
            <Col span={16}>
              <ForzaCard userInfo={userInfo} />
            </Col>
            <Col span={8}>
              <ProfileCard userInfo={childInfo} />
            </Col>
          </Row>
          <Row gutter={24} className="mt-20 teacherHomePage">
            <Col span={12}>
              <Card
                style={{ height: "475px", position: "relative" }}
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ marginTop: "3px" }}>Ödevler</div>
                    <div>
                      <NavigateButton url={"/odevler"} />
                    </div>
                  </div>
                }
              >
                <div style={{ position: "absolute", bottom: 0, right: 10 }}>
                  <img
                    src={announcementPng}
                    alt="Fotoğraf"
                    style={{ width: "250px", height: "150px" }}
                  />
                </div>
                <Row gutter={24}>
                  <Col span={14}>
                    <PieChartComponent
                      data={[
                        { name: "Matematik", count: 10 },
                        { name: "Fizik", count: 15 },
                        { name: "Türkçe", count: 7 },
                      ]}
                    />
                  </Col>
                  <Col span={10}>
                    {homeworkArray.map((item, i) => (
                      <Card
                        key={i}
                        style={{
                          backgroundColor: "#F5F5F5",
                          marginTop: i === 0 ? 0 : "3px",
                          height: "80px",
                        }}
                      >
                        <Row style={{ fontSize: "13px" }}>{item.title}</Row>
                        <Row style={{ fontSize: "13px" }}>
                          {item.end_date.split("T")[0]}
                        </Row>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ marginTop: "3px" }}>Duyurular</div>
                    <div>
                      <NavigateButton url={"/duyurular"} />
                    </div>
                  </div>
                }
                style={{ height: "475px", position: "relative" }}
              >
                <div style={{ position: "absolute", bottom: 0, right: 10 }}>
                  <img
                    src={announcementPng}
                    alt="Fotoğraf"
                    style={{ width: "250px", height: "150px" }}
                  />
                </div>
                <Timeline items={announcementArray} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <TimeTable scheduleArray={scheduleArray} type="Student" />
        </Col>
      </Row>
    </>
  );
};

export default ParentHomePage;
