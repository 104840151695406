import React from "react";
import { Avatar, Button, Card, Col, Popconfirm, Row, Tag, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { delete_ } from "../../helper";
import EmptyPage from "../../components/EmptyPage";

const OpinionCard = ({
  type,
  opinionArray,
  getUser,
  setInfo,
  setIsModalOpen,
}) => {
  const onClickDelete = async (note_id) => {
    const result = await delete_(`student/note/${note_id}`);
    if (result.data.success) {
      message.success(result.data.detail);
      getUser();
    } else message.error(result.data.detail);
  };

  return (
    <>
      <Row gutter={[16, 16]} className="mt-20">
        {opinionArray.length === 0 ? (
          <EmptyPage />
        ) : (
          opinionArray.map((item) => (
            <Col key={item.id} md={24} lg={12}>
              <Card key={item.id}>
                <Row justify="space-between">
                  <Col />
                  <Col>
                    <Tag color={item.talked_to === "" ? "green" : "#7365FF"}>
                      {item.talked_to === ""
                        ? "Öğretmen Görüşü"
                        : "Veli Görüşmesi"}
                    </Tag>
                    {type === "Teacher" && (
                      <>
                        {" "}
                        <Button
                          className="borderNone green boxShadowNone"
                          style={{ marginRight: "-15px" }}
                          onClick={() => [setIsModalOpen(true), setInfo(item)]}
                        >
                          <EditOutlined />
                        </Button>
                        <Popconfirm
                          title="Görüş silme işlemi"
                          description="Görüşü silmek istediğinizden emin misiniz ?"
                          onConfirm={() => onClickDelete(item.id)}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Button className="borderNone boxShadowNone">
                            <DeleteOutlined className="red" />
                          </Button>
                        </Popconfirm>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Avatar
                      size="medium"
                      src="https://api.dicebear.com/7.x/miniavs/svg?seed=3"
                    />
                  </Col>
                  <Col className="mt-10" style={{ color: "gray" }}>
                    {type === "Teacher"
                      ? `${item.student.first_name} ${item.student.last_name}`
                      : `${item.teacher_id.first_name} ${item.teacher_id.last_name} ,`}
                  </Col>
                  {/* <Col className="mt-10" style={{ color: "gray" }}>
                    {type === "Teacher"
                      ? `Sınıfı gelicek`
                      : `${item?.teacher_id?.lesson_id?.at(0).name}`}
                  </Col> */}
                </Row>
                <Row className="mt-10" style={{ textAlign: "left" }}>
                  {item.note}
                </Row>
                <Row gutter={24} className="mt-10">
                  <Col span={6} align="left" style={{ color: "gray" }}>
                    Tarih
                  </Col>
                  <Col span={2} style={{ color: "gray" }}>
                    :
                  </Col>
                  <Col span={8} style={{ color: "#579BFE" }}>
                    {item.date.split("T")[0]}
                  </Col>
                </Row>
                {item.talked_to !== "" && (
                  <Row>
                    <Col>
                      <Avatar
                        size="medium"
                        src="https://api.dicebear.com/7.x/miniavs/svg?seed=3"
                      />
                    </Col>
                    <Col className="mt-10" style={{ color: "gray" }}>
                      {`${item.talked_to}`}
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

export default OpinionCard;
