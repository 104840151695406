import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { delete_, get } from "../../../helper";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import AddClass from "./AddClass";
import { classContinue } from "../../../services";
import { HowToComponent } from "../../../components";
import FileDownload from "../../../helper/FileDownload";

const Page = () => {
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [info, setInfo] = useState(undefined);
  const [filterKey, setFilterKey] = useState();
  const [loading, setLoading] = useState(true);
  const [filterView, setFilterView] = useState(true);

  const columns = [
    {
      title: "Sınıf Adı",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Sınıf",
      dataIndex: "grade",
      key: "grade",
      align: "center",
    },
    {
      title: "Şubeler",
      dataIndex: "sections",
      key: "sections",
      align: "center",
      render: (_, { sections }) => (
        <>
          {sections.map((section) => {
            return (
              <Tag color="green" key={section._id}>
                {section.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Dersler",
      dataIndex: "lessons",
      key: "lessons",
      align: "center",
      render: (_, { lessons }) => (
        <>
          {lessons.map((lesson) => {
            return (
              <Tag color="blue" key={lesson._id}>
                {lesson.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "İşlemler",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editClass(record)} className="borderNone">
            <EditOutlined className="green" />
          </Button>
          <Popconfirm
            title="Sınıfı silme işlemi"
            description="Sınıfı silmek istediğinizden emin misiniz ?"
            onConfirm={() => onConfirmDelete(record._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button className="borderNone">
              <DeleteOutlined className="red" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const editClass = (record) => {
    setIsModalOpen(true);
    setInfo(record);
  };

  const onConfirmDelete = async (id) => {
    const result = await delete_(`${classContinue}${id}`);
    if (result.status === 200) {
      message.success(id + " Başarıyla silindi");
      getData();
    } else message.error("Sorun oldu");
  };

  const showModal = () => {
    setInfo(undefined);
    setIsModalOpen(true);
  };

  const onChangeSearchBtn = (e) => {
    setFilterKey(e.target.value);
  };

  const getData = async () => {
    setLoading(true);
    const result = await get(
      classContinue,
      null,
      filterKey ? { keyword: filterKey } : null
    );
    if (result.status === 200) {
      result?.data?.detail?.map((item, index) => (item.key = index));
      setDataSource(result.data.detail);
      setLoading(false);
    } else message.error("Bir hata oluştu");
  };

  useEffect(() => {
    getData();
  }, [filterKey]);

  return (
    <>
      <Row justify="space-between" align="middle" className="mt-30">
        <Col>
          <FileDownload
            model={"student"}
            type={"export"}
            // queryDownload={queryDownload}
          />
        </Col>
        <Col>
          <HowToComponent />
        </Col>
      </Row>
      <Row justify="space-between" className="mt-10" style={{marginBottom:"10px"}}>
        <Col></Col>
        <Col>
          <Button
            style={{
              backgroundColor: "#F5F5F5	",
              border: "none",
              color: "black",
              height: "40px",
              marginRight: "10px",
            }}
            size={"large"}
            icon={<FilterOutlined />}
            onClick={() => setFilterView((prev) => !prev)}
          />
          <Button
            style={{
              backgroundColor: "#7366F9",
              color: "white",
              borderRadius: "5px",
              height: "40px",
            }}
            icon={<PlusOutlined />}
            size={"large"}
            onClick={showModal}
          >
            Yeni Sınıf Ekle
          </Button>
        </Col>
      </Row>
      {filterView && (
        <Form form={form} name="basic">
          <Form.Item label="Sınıf Düzeyi">
            <Row>
              <Input
                style={{ borderRadius: "20px" }}
                placeholder="Sınıf düzeyi giriniz"
                onChange={onChangeSearchBtn}
              ></Input>
            </Row>
          </Form.Item>
        </Form>
      )}
      <Row className="mt-20">
        <Table
          loading={loading}
          className="w-100"
          dataSource={dataSource}
          columns={columns}
        />
      </Row>
      {isModalOpen && (
        <AddClass
          info={info}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          getData={getData}
        />
      )}
    </>
  );
};

export default Page;
