import React, { useEffect, useState } from "react";
import {
  HomeOutlined,
  FormOutlined,
  UnorderedListOutlined,
  LineChartOutlined,
  ContainerOutlined,
  BarChartOutlined,
  BookOutlined,
  CalendarOutlined,
  FolderOutlined,
  FileTextOutlined,
  CommentOutlined,
  ScheduleOutlined,
  CarryOutOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserData } from "../helper";
import efsoImg from "../assets/media/efso.png";
import batteryImg from "../assets/media/battery.png";

const { Sider } = Layout;

const MenuLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const [collapsed, setCollapsed] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menuItems = [
    { label: "Ana Sayfa", key: "/anaSayfa", icon: <HomeOutlined /> },
    {
      children: [
        {
          icon: <ContainerOutlined />,
          key: "/kayitIslemleri",
          label: "Kayıt İşlemleri",
        },
        {
          icon: <LineChartOutlined />,
          key: "/subeAtlatma",
          label: "Şube Atlatma",
        },
      ],
      label: "Kayıt İşlemleri",
      key: "/kayıt",
      icon: <FormOutlined />,
    },
    {
      label: "Yoklama İşlemleri",
      key: "/yoklamaIslemleri",
      icon: <UnorderedListOutlined />,
    },

    {
      children: [
        {
          children: [
            {
              icon: <ContainerOutlined />,
              key: "/senetIslemleri",
              label: "Senet Oluşturma",
            },
            {
              icon: <FileTextOutlined />,
              key: "/senetListeleri",
              label: "Senet Listeleri",
            },
            {
              icon: <LineChartOutlined />,
              key: "/performans",
              label: "Performans",
            },
          ],
          label: "Senet",
          key: "/senet",
          icon: <UnorderedListOutlined />,
        },
        {
          label: "Ödeme Takip",
          key: "/odemeTakip",
          icon: <UnorderedListOutlined />,
        },
      ],
      icon: <ContainerOutlined />,
      key: "/muhasebe",
      label: "Muhasebe",
    },
    {
      label: "SIHA Raporlama",
      key: "/sihaRaporlama",
      icon: <BarChartOutlined />,
    },
    {
      label: "Görüşler",
      key: "/gorusler",
      icon: <CommentOutlined />,
    },
    {
      label: "Takvim",
      key: "/takvim",
      icon: <ScheduleOutlined />,
    },
    {
      label: "Görevlendirme",
      key: "/gorevlendirme",
      icon: <CarryOutOutlined />,
    },
    { label: "Ödevler", key: "/odevler", icon: <BookOutlined /> },
    {
      label: "Ders Programı",
      key: "/dersProgrami",
      icon: <CalendarOutlined />,
    },
    { label: "Duyurular", key: "/duyurular", icon: <NotificationOutlined /> },
    {
      label: "Paragraf Pili",
      key: "/paragrafPili",
      icon: (
        <img
          src={batteryImg}
          alt="Efso Koç"
          className={
            collapsed ? "paragrafPiliIconCollapsed" : "paragrafPiliIcon"
          }
        />
      ),
    },
    {
      label: "Efso Koç",
      key: "/efsoKoc",
      icon: (
        <img
          src={efsoImg}
          alt="Efso Koç"
          className={collapsed ? "efsoIconCollapsed" : "efsoIcon"}
        />
      ),
    },
  ];

  const menuItemsParent = [
    { label: "Ana Sayfa", key: "/anaSayfa", icon: <HomeOutlined /> },
    {
      children: [
        {
          icon: <ContainerOutlined />,
          key: "/kayitIslemleri",
          label: "Kayıt İşlemleri",
        },
        {
          icon: <LineChartOutlined />,
          key: "/subeAtlatma",
          label: "Şube Atlatma",
        },
      ],
      label: "Kayıt İşlemleri",
      key: "/kayıt",
      icon: <FormOutlined />,
    },
    {
      label: "Yoklama İşlemleri",
      key: "/yoklamaIslemleri",
      icon: <UnorderedListOutlined />,
    },
    {
      label: "Performans",
      key: "/performans",
      icon: <BarChartOutlined />,
    },
    {
      label: "SIHA Raporlama",
      key: "/sihaRaporlama",
      icon: <BarChartOutlined />,
    },
    {
      label: "Görüşler",
      key: "/gorusler",
      icon: <CommentOutlined />,
    },
    { label: "Ödevler", key: "/odevler", icon: <BookOutlined /> },
    {
      label: "Ders Programı",
      key: "/dersProgrami",
      icon: <CalendarOutlined />,
    },
    { label: "Duyurular", key: "/duyurular", icon: <FolderOutlined /> },
  ];

  const getUser = async () => {
    try {
      const result = await GetUserData();
      setUserRole(result.data.role);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if(window.innerWidth < 800) setCollapsed(true);
    const handleResize = () => {
      setPageWidth(window.innerWidth);
      if (window.innerWidth < 1000) setCollapsed(true);
      else setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pageWidth]);

  const defaultMenuItems = userRole === "Parent" ? menuItemsParent : menuItems;

  const filteredMenuItems = defaultMenuItems.filter((item) => {
    if (userRole === "Student") {
      return (
        item.key === "/anaSayfa" ||
        item.key === "/yoklamaIslemleri" ||
        item.key === "/sihaRaporlama" ||
        item.key === "/odevler" ||
        item.key === "/dersProgrami" ||
        item.key === "/duyurular"
      );
    } else if (userRole === "Parent") {
      return (
        item.key === "/anaSayfa" ||
        item.key === "/yoklamaIslemleri" ||
        item.key === "/performans" ||
        item.key === "/gorusler" ||
        item.key === "/odevler" ||
        item.key === "/dersProgrami" ||
        item.key === "/duyurular"
      );
    } else if (userRole === "Teacher") {
      return (
        item.key === "/anaSayfa" ||
        item.key === "/yoklamaIslemleri" ||
        item.key === "/performans" ||
        item.key === "/sihaRaporlama" ||
        item.key === "/gorusler" ||
        item.key === "/gorevlendirme" ||
        item.key === "/odevler" ||
        item.key === "/dersProgrami" ||
        item.key === "/duyurular"
      );
    } else {
      return (
        item.key === "/anaSayfa" ||
        item.key === "/kayıt" ||
        item.key === "/yoklamaIslemleri" ||
        item.key === "/muhasebe" ||
        item.key === "/gorusler" ||
        item.key === "/takvim" ||
        item.key === "/gorevlendirme" ||
        item.key === "/odevler" ||
        item.key === "/dersProgrami" ||
        item.key === "/duyurular" ||
        item.key === "/efsoKoc" ||
        item.key === "/paragrafPili"
      );
    }
  });

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="demo-logo-vertical" />
      <Menu
        style={{
          overflow: "auto",
          height: "100vh",
          width: collapsed ? "82px" : "200px",
          position: "fixed",
          left: 0,
          top: 63,
          fontSize: "15px",
          fontWeight: 600,
        }}
        onClick={({ key }) => {
          if (key === "signout") {
          } else {
            navigate(key);
          }
        }}
        mode="inline"
        selectedKeys={[`/${path}`]}
        items={filteredMenuItems}
      />
    </Sider>
  );
};

export default MenuLayout;
