import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
  Select,
  Steps,
  message,
} from "antd";
import { CustomBillInput, CustomNumberInput } from "../../components";
import {
  formatResultMultipleName,
  get,
  getBlob,
  post,
  put,
} from "../../helper";
import { DownCircleOutlined } from "@ant-design/icons";
import manPhoto from "../../assets/media/man.png";
import { studentContinue } from "../../services";
import howTo from "../../assets/media/howTo.png";

const Page = () => {
  const [form] = Form.useForm();

  const [studentArray, setStudentArray] = useState([]);
  const [studentInfo, setStudentInfo] = useState(undefined);
  const [current, setCurrent] = useState();
  const [taksit, setTaksit] = useState(undefined);
  const [tutar, setTutar] = useState(undefined);
  const [items, setItems] = useState([]);
  const [selectValue, setSelectValue] = useState();
  const [disabled, setDisabled] = useState(false);
  const [days, setDays] = useState([]);
  const [billPdfUrl, setBillPdfUrl] = useState();
  const [loading, setLoading] = useState(false);

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  function saveInput() {
    const { amount, installments } = form.getFieldsValue();
    let amountValue1;
    if (typeof amount === "string") amountValue1 = amount;
    else amountValue1 = String(amount);
    var amountValue = amountValue1.includes(",")
      ? amountValue1.split(",").join("")
      : amountValue1;
    const title = amountValue1.includes(",")
      ? `${amountValue / installments}`
      : `${amountValue / installments}`;
    const intValue = title.split(".")?.[0];
    const decimalValue = title.split(".")?.[1]?.substring(0, 2);
    var resultString =
      decimalValue !== undefined ? intValue + "." + decimalValue : intValue;
    setTaksit(installments);
    setTutar(amountValue);
    const newItems = [];
    for (let i = 1; i <= installments; i++) {
      newItems.push({
        title: `${resultString} TL`,
        description: `${i}. Taksit`,
      });
    }
    setItems(newItems);
  }
  const processChange = debounce(() => saveInput());

  const onChange = (value) => setCurrent(value);

  const onFinish = async (values) => {
    setLoading(true);
    values.amount = tutar;
    const result = await post("payment/", values);
    if (result.status === 200) message.success("Senet başarıyla oluşturuldu");
    else message.error("Hata oluştu");
    handleBlur("bill");
    setLoading(false);
  };

  const onSearchStudentSelect = async (e) => {
    const inputLength = e.length;
    if (inputLength >= 3) {
      const result = await get(studentContinue, null, { keyword: e });
      setStudentArray(
        formatResultMultipleName(
          result.data.detail,
          "first_name",
          "last_name",
          "_id"
        )
      );
    } else
      setStudentArray([
        { label: "En az 3 harf girin", value: 1, disabled: true },
      ]);
  };

  const onChangeStudentName = async (e) => {
    if (e !== undefined) {
      const result = await get(`${studentContinue}${e}`);
      setStudentInfo(result.data);
      setSelectValue(result.data._id);
    } else setStudentInfo(undefined);
  };

  const handleBlur = async (type = "installment") => {
    const studentName = form.getFieldsValue().student_id;
    form.resetFields();
    form.setFieldsValue({ student_id: studentName });
    const result = await get(`payment/${selectValue}`);
    if (type === "installment") {
      handleDownload(
        result.data.detail.paid_installments[
          result.data.detail.paid_installments.length - 1
        ].file
      );
    }
    setBillPdfUrl(result.data.detail.agreement);
    if (!result.data.success) setDisabled(false);
    else {
      setCurrent(result.data.detail.paid_installments?.length - 1);
      const { amount, installments, payment_day } = result.data.detail;
      form.setFieldsValue({
        amount: amount,
        installments: installments,
        payment_day: payment_day,
      });
      saveInput(result.data.detail.paid_installments?.length);
      setDisabled(true);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const dayOfMounth = [];
    for (let i = 1; i <= 31; i++) {
      dayOfMounth.push({ label: i, value: i });
    }
    setDays(dayOfMounth);
    form.setFieldsValue({ installments: taksit });
  }, [taksit, form]);

  const handleSubmit = async () => {
    const result = await put(`payment/${studentInfo._id}/${current + 1}`);
    if (result.status === 200) message.success("Ödeme Başarıyla alındı");
    else message.error("Hata oluştu");
    handleBlur("installment");
  };

  const handleDownload = async (e = undefined) => {
    try {
      const fileUrl = e
        ? `${process.env.REACT_APP_API_URL}${e}`
        : `${process.env.REACT_APP_API_URL}${billPdfUrl}`;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        e
          ? `${studentInfo.first_name} ${studentInfo.last_name} ${
              current + 1
            }.Taksit.pdf`
          : `${studentInfo.first_name} ${studentInfo.last_name}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error("Dosya indirilemedi");
    }
  };

  return (
    <>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Card className="boxShadow">
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Öğrenci"
                    name="student_id"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen öğrenci seçiniz!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      options={studentArray}
                      placeholder="Öğrencinin adını girin (en az 3 karakter)"
                      filterOption={filterOption}
                      onSearch={onSearchStudentSelect}
                      onBlur={handleBlur}
                      onChange={onChangeStudentName}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Toplam Tutar"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen toplam tutar girin!",
                      },
                    ]}
                  >
                    <CustomBillInput
                      digitsOfNumber={11}
                      disabled={disabled}
                      placeholder="Toplam Tutarı Giriniz"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Taksit Sayısı"
                    name="installments"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen taksit sayısı girin!",
                      },
                    ]}
                  >
                    <CustomNumberInput
                      onChange={processChange}
                      digitsOfNumber={11}
                      placeholder="Taksit Sayısı Giriniz"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Ödeme Tarihi"
                    name="payment_day"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen taksit sayısı girin!",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      options={days}
                      placeholder="Taksit günü giriniz"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!disabled && (
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        loading={loading}
                        htmlType="submit"
                        style={{ backgroundColor: "#7365FE", color: "white" }}
                      >
                        Senet Kaydet
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
          <Col span={16}>
            {studentInfo === undefined ? (
              <Row className="mt-30">
                <Col span={6} className="mt-30">
                  <Image src={howTo} preview={false} />
                </Col>
                <Col span={18}>
                  <h1 style={{ borderBottom: "2px solid green" }}>
                    Senet İşlemleri Modülüne Hoş Geldiniz!
                  </h1>
                  <p>
                    Öğrenci adını girdikten sorna, öğrencinin veli ve kendi
                    bilgilerini görebileceksiniz. Herhangi bir sorunuz veya
                    yardıma ihtiyacınız olursa, lütfen bize ulaşın
                  </p>
                </Col>
              </Row>
            ) : (
              <Col span={24}>
                <Card
                  className="boxShadow mt-30"
                  style={{
                    background: "linear-gradient(45deg, #B675F3, #7D36C0)",
                    color: "white",
                  }}
                >
                  <Row gutter={24}>
                    <Col span={4}>
                      <Image
                        style={{ borderRadius: "10px" }}
                        src={manPhoto}
                        alt="Profile"
                        width={100}
                        height={100}
                        preview={false}
                      />
                    </Col>
                    <Col span={9} push={2}>
                      <Row className="mt-10">
                        {`${studentInfo?.parent?.first_name} ${studentInfo?.parent?.last_name}`}
                      </Row>
                      <Row className="mt-10">{`${studentInfo?.parent?.tckn}`}</Row>
                      <Row className="mt-10">{`${studentInfo?.parent?.phone}`}</Row>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        fontSize: "90px",
                        textAlign: "center",
                        marginTop: "-10px",
                      }}
                    >
                      <span
                        style={{
                          borderRight: "2px solid white",
                          padding: "0 10px",
                        }}
                      />
                    </Col>

                    <Col span={9} push={2}>
                      <Row>{`${studentInfo.first_name} ${studentInfo.last_name}`}</Row>
                      <Row>{`${studentInfo.class_id?.name} / ${studentInfo.section_id?.name}`}</Row>
                      <Row>{`${studentInfo.student_no}`}</Row>
                      <Row>{`${studentInfo.tckn}`}</Row>
                      <Row>{`${studentInfo.phone}`}</Row>
                    </Col>
                  </Row>
                  <Row justify="center" align="center" className="mt-10">
                    <Button
                      className="btn"
                      icon={<DownCircleOutlined />}
                      onClick={() => handleDownload()}
                    >
                      Seneti İndir
                    </Button>
                  </Row>
                </Card>
              </Col>
            )}
          </Col>
        </Row>
        {items.length > 0 && (
          <>
            <Row gutter={24} className="mt-30">
              <Col span={24}>
                <div className="custom-steps-container mt-10">
                  <Steps
                    className="custom-steps"
                    progressDot
                    current={current}
                    onChange={onChange}
                    direction="horizontal"
                    items={items}
                    size="small"
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={24} className="mt-20">
              <Col span={6} push={9}>
                <Button
                  style={{
                    border: "1px solid orange",
                    padding: "3px 20px",
                    borderRadius: "20px",
                  }}
                  onClick={handleSubmit}
                >
                  Ödeme Al
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
};
export default Page;
