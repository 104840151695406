import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import ChildrensPage from "./ChildrensPage";

const MultipleChildrenParent = ({ children }) => {
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(undefined);

  const editTabs = () => {
    const arr = [];
    children.map((item, i) =>
      arr.push({
        key: i,
        label: `${item.first_name} ${item.last_name}`,
        children: <ChildrensPage info={item} pageNumber={pageNumber} />,
      })
    );
    setItems(arr);
  };

  useEffect(() => {
    editTabs();
  }, [pageNumber]);

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      items={items}
      centered
      onChange={(e) => setPageNumber(e)}
    />
  );
};

export default MultipleChildrenParent;
