import React, { useEffect, useState } from "react";
import { delete_, get } from "../../helper";
import { Button, Popconfirm, Space, Spin, Table, message } from "antd";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { formatDate } from "../../helper/FormatDate";

const Page = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(undefined);
  const [query, setQuery] = useState({
    page: 0,
    page_size: 10,
    sort: "-_id",
  });
  const [loadingTable, setLoadingTable] = useState(true);

  const onConfirmDelete = async (record) => {
    const result = await delete_(`payment/${record.student_id.id}`);
    if (result.data.success)
      message.success(
        `${record.student_id.first_name} ${record.student_id.last_name} adlı öğrencinin seneti silinmiştir`
      );
    else message.error("Senet silme işlemi başarısız");
    getData();
  };

  const columns = [
    {
      title: "Öğrenci Adı",
      render: (text, record) => (
        <span>{`${record.student_id.first_name} ${record.student_id.last_name}`}</span>
      ),
      key: "name",
      align: "center",
    },
    {
      title: "Senet Tutarı",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: "Ödeme Günü",
      dataIndex: "payment_day",
      key: "payment_day",
      align: "center",
    },
    {
      title: "Senet Tarihi",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Pdf İndir",
      dataIndex: "aggrement",
      key: "aggrement",
      align: "center",
      render: (text, record) => (
        <CloudDownloadOutlined
          style={{ cursor: "pointer", color: "orange", fontSize: "20px" }}
          onClick={() => {
            const url = `https://api.sihaotomasyon.com/${record.agreement}`;
            window.open(url, "_blank");
          }}
        />
      ),
    },
    {
      title: "İşlemler",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {/* <Button onClick={() => editClass(record)} className="borderNone">
            <EditOutlined className="green" />
          </Button> */}
          <Popconfirm
            title="Sınıfı silme işlemi"
            description="Sınıfı silmek istediğinizden emin misiniz ?"
            onConfirm={() => onConfirmDelete(record)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button className="borderNone">
              <DeleteOutlined className="red" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onChangeTable = (e) => {
    const object = {
      page: e.current - 1,
      page_size: e.pageSize,
      sort: "-_id",
    };
    setQuery(object);
  };

  const getData = async () => {
    setLoadingTable(true);
    const result = await get("payment/", null, query);
    setTotalCount(result.data.total_count);
    result.data.detail.map((item, i) => [
      (item.key = i),
      (item.date = item.date.split("T")[0]),
    ]);
    result.data.detail.map(item => item.date = formatDate(item.date))
    setDataSource(result.data.detail);
    setLoading(false);
    setLoadingTable(false);
  };

  useEffect(() => {
    getData();
  }, [query]);

  return loading ? (
    <Spin />
  ) : (
    <Table
      loading={loadingTable}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total: totalCount,
        showTotal: (total, range) => (
          <span className="text-muted">
            <b>{totalCount}</b> kayıttan <b>{range[0]}</b> - <b>{range[1]}</b>{" "}
            arası
          </span>
        ),
      }}
      onChange={onChangeTable}
    />
  );
};

export default Page;
