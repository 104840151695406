import { get } from "./HttpHelpers";

export const GetUserData = async () => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData === null) {
        const meResult = await get("me");
        localStorage.setItem('userData', JSON.stringify(meResult));
    }
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    return userData;
};