import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Select, Table, message } from "antd";
import { formatResultWith, get, put } from "../../helper";
import { classContinue, studentContinue } from "../../services";
import { SwapOutlined } from "@ant-design/icons";
import { columns } from "./Constant";

const Page = () => {
  const [form] = Form.useForm();

  const [sectionArray, setSectionArray] = useState([]);
  const [newSectionArray, setNewSectionArray] = useState([]);
  const [classArray, setClassArray] = useState([]);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(undefined);
  const [query, setQuery] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await put(
      `${studentContinue}move/${values.class_id}/${values.section_id}/${values.newClass_id}/${values.newSection_id}`
    );
    if (result.status === 200)
      message.success("Başarılı bir şekilde işlem gerçekleşmiştir");
    else message.error("Hata oluştu");
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeClass = (e) => {
    const arr = data.find((item) => item._id === e);
    setSectionArray(formatResultWith(arr?.sections, "name", "id"));
    form.setFieldsValue({ section_id: undefined });
    setDataSource([]);
  };

  const onchangeSection = async () => {
    const values = form.getFieldsValue();
    const object = {
      ...query,
      class_id: values.class_id,
      section_id: values.section_id,
    };
    const result = await get("student/", null, object);
    if (result.data.detail.length === 0) setDisabled(true);
    else setDisabled(false);
    result.data.detail.map((item, index) => (item.key = index));
    setTotalCount(result.data.total_count);
    if (values.section_id) setDataSource(result.data.detail);
    else setDataSource([]);
  };

  const onChangeNewClass = (e) => {
    const arr = data.find((item) => item._id === e);
    setNewSectionArray(formatResultWith(arr.sections, "name", "id"));
  };

  const getClassData = async () => {
    const result = await get(`${classContinue}`);
    setData(result.data.detail);
    setClassArray(
      formatResultWith(result.data.detail, "name", "_id").sort(function (a, b) {
        return a.value - b.value;
      })
    );
  };

  const onChangeTable = (e) => {
    const object = {
      page: e.current - 1,
      page_size: e.pageSize,
    };
    setQuery(object);
  };

  useEffect(() => {
    getClassData();
  }, []);

  useEffect(() => {
    onchangeSection();
  }, [query]);

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Card>
              <Row style={{ fontWeight: 700 }}>Kaynak Şube Bilgileri</Row>
              <Row gutter={24} className="mt-20">
                <Col span={12}>
                  <Form.Item
                    name="class_id"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bir sınıf seçiniz!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Sınıf"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      options={classArray}
                      onChange={onChangeClass}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="section_id"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bir şube seçiniz!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Şube"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      options={sectionArray}
                      onChange={onchangeSection}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-10" justify="center" align="middle">
                <SwapOutlined
                  style={{
                    fontSize: "30px",
                    transform: "rotate(90deg)",
                    color: "#3525CE",
                    backgroundColor: "#A099EB",
                    borderRadius: "100%",
                    padding: "10px",
                  }}
                />
              </Row>
              <Row style={{ fontWeight: 700 }} className="mt-30">
                Hedef Şube Bilgileri
              </Row>
              <Row gutter={24} className="mt-20">
                <Col span={12}>
                  <Form.Item
                    name="newClass_id"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bir seçenek belirtiniz!",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      placeholder="Sınıf"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      options={classArray}
                      onChange={onChangeNewClass}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="newSection_id"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bir seçenek belirtiniz!",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      placeholder="Şube"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      options={newSectionArray}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-20">
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#4D47CE",
                    color: "white",
                  }}
                  onClick={() => form.submit()}
                  loading={loading}
                >
                  Şube Atlat
                </Button>
              </Row>
            </Card>
          </Col>
          <Col span={16}>
            <Row>
              <Table
                className="mt-30"
                style={{ width: "70%", marginLeft: "15%" }}
                dataSource={dataSource}
                columns={columns}
                pagination={
                  dataSource.length > 0
                    ? {
                        total: totalCount,
                        showTotal: (total, range) => (
                          <span className="text-muted">
                            <b>{totalCount}</b> kayıttan <b>{range[0]}</b> -{" "}
                            <b>{range[1]}</b> arası
                          </span>
                        ),
                      }
                    : undefined
                }
                onChange={onChangeTable}
              />
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Page;
