import { Button, Col, Popconfirm, Row, Space, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { get, delete_ } from "../../../helper";
import FileDownload from "../../../helper/FileDownload";
import FileUpload from "../../../helper/FileUpload";
import { HowToComponent } from "../../../components";
import Filter from "./Filter";
import AddStudent from "./AddStudent";

const Page = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentsData, setStudentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(undefined);
  const [totalCount, setTotalCount] = useState(undefined);
  const [query, setQuery] = useState({});
  const [queryDownload, setQueryDownload] = useState({});
  const [filterView, setFilterView] = useState(true);

  const showModal = () => {
    setInfo(undefined);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "İsim",
      dataIndex: "first_name",
      key: "name",
      align: "center",
    },
    {
      title: "Soyisim",
      dataIndex: "last_name",
      key: "surname",
      align: "center",
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      key: "gender",
      align: "center",
    },
    {
      title: "Öğrenci No",
      dataIndex: "student_no",
      key: "studentNo",
      align: "center",
    },
    {
      title: "Sınıf",
      dataIndex: "class_name",
      key: "class_name",
      align: "center",
    },
    {
      title: "Şube",
      dataIndex: "section_name",
      kkey: "section_name",
      align: "center",
    },
    {
      title: "Kimlik No",
      dataIndex: "tckn",
      key: "identityNo",
      align: "center",
    },
    {
      title: "Telefon No",
      dataIndex: "phone",
      key: "identityNo",
      align: "center",
    },
    {
      title: "İşlemler",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editStudent(record)} className="borderNone">
            <EditOutlined className="green" />
          </Button>
          <Popconfirm
            title="Öğrenci silme işlemi"
            description="Öğrenciyi silmek istediğinizden emin misiniz ?"
            onConfirm={() => onConfirmDelete(record._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button className="borderNone">
              <DeleteOutlined className="red" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const editStudent = (record) => {
    setInfo(record);
    setIsModalOpen(true);
  };

  const onConfirmDelete = async (id) => {
    const result = await delete_(`student/${id}`);
    if (result.status === 200) {
      message.success("Öğrenci silme işlemi başarılı");
      getData();
    } else message.error("Öğrenci silme işlemi başarısız");
  };

  const getData = async () => {
    const obj = {
      ...queryDownload,
      ...query,
    };
    setLoading(true);
    const result = await get("student/", null, obj);
    if (result.status === 200) {
      setTotalCount(result.data.total_count);
      result.data.detail.map((item, index) => (item.key = index));
      setStudentsData(result.data.detail);
      setLoading(false);
    } else message.error("Öğrenci bilgileri gelmedi");
  };

  const onChangeTable = (e) => {
    const object = {
      page: e.current - 1,
      page_size: e.pageSize,
    };
    setQuery(object);
  };

  useEffect(() => {
    const obj = {
      ...queryDownload,
      ...query,
    };
    getData(obj);
  }, [query, queryDownload]);

  return (
    <>
      <Row justify="space-between" className="mt-30">
        <Col style={{marginRight:"5px"}}>
          <FileDownload model={"student"} />
        </Col>
        <Col style={{marginRight:"5px"}}>
          <FileUpload type={"student"} getData={getData} />
        </Col>
        <Col>
          <FileDownload
            model={"student"}
            type={"export"}
            queryDownload={queryDownload}
          />
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          <HowToComponent />
        </Col>
      </Row>
      <Row justify="space-between" className="mt-10 mb--20">
        <Col style={{ textAlign: "left" }}></Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            style={{
              backgroundColor: "#F5F5F5	",
              border: "none",
              color: "black",
              height: "40px",
              marginRight: "10px",
            }}
            size={"large"}
            icon={<FilterOutlined />}
            onClick={() => setFilterView(prev => !prev)}
          />
          <Button
            style={{
              backgroundColor: "#7366F9",
              color: "white",
              borderRadius: "5px",
              height: "40px",
            }}
            icon={<PlusOutlined />}
            size={"large"}
            onClick={showModal}
          >
            Bireysel Öğrenci Ekle
          </Button>
          <AddStudent
            getData={getData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            info={info}
          />
        </Col>
      </Row>
      {filterView && <Filter getData={getData} setQueryDownload={setQueryDownload} />}
      <Row gutter={24} className="mt-30">
        <Col span={24}>
          <Table
            className="w-100"
            dataSource={studentsData}
            columns={columns}
            loading={loading}
            pagination={{
              // showSizeChanger: true,
              total: totalCount,
              showTotal: (total, range) => (
                <span className="text-muted">
                  <b>{totalCount}</b> kayıttan <b>{range[0]}</b> -{" "}
                  <b>{range[1]}</b> arası
                </span>
              ),
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
    </>
  );
};

export default Page;
