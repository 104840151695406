import React, { useEffect, useState } from "react";
import { GetUserData } from "../../helper";
import { Card, Col, Row, Table, Spin } from "antd";
import { columns } from "./Constant";

const ChildrensPage = ({ info, pageNumber }) => {
  const [dataSource, setDataSource] = useState([]);
  const [rollCallInfo, setRollCallInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const getUser = async (e = 0) => {
    try {
      const result = await GetUserData();
      const children = result?.data?.children;
      if (children && children.length > e) {
        const raporluSayisi = children[e]?.absences?.filter(
          (item) => item.type === "Raporlu"
        )?.length;
        const izinliSayisi = children[e]?.absences?.filter(
          (item) => item.type === "İzinli"
        )?.length;
        const devamsızSayisi = result.data.children[0].absences.filter(function (
          item
        ) {
          return item.type === "Devamsız";
        }).length;
        setRollCallInfo({
          raporlu: raporluSayisi,
          izinli: izinliSayisi,
          devamsız : devamsızSayisi,
        });

        const updatedDataSource =
          children[e]?.absences?.map((item, i) => ({
            ...item,
            key: i,
            date: item.date.split("T")[0],
          })) || [];

        setDataSource(updatedDataSource);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser(pageNumber);
  }, [info, pageNumber]);

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Row gutter={24}>
          <Col span={12} push={6}>
            <Row gutter={24}>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    opacity: 0.7,
                  }}
                >
                  {rollCallInfo.devamsız}
                </Card>
              </Col>
              <Col span={4} className="mt-20">
                Devamsızlık
              </Col>

              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "yellow",
                    color: "black",
                    opacity: 0.7,
                  }}
                >
                  {rollCallInfo.raporlu}
                </Card>
              </Col>
              <Col span={4} className="mt-20">
                Raporlu
              </Col>

              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    opacity: 0.7,
                  }}
                >
                  {rollCallInfo.izinli}
                </Card>
              </Col>
              <Col span={4} className="mt-20">
                İzinli
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {dataSource.length === 0 ? (
        <p>Devamsızlık bilgisi yok</p>
      ) : (
        <Row gutter={24} className="mt-30">
          <Col span={12} push={6}>
            <Table bordered dataSource={dataSource} columns={columns} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ChildrensPage;
