export const formatResultWith = (
    datas,
    label = "name",
    value = "id"
) => {
    return datas
        ?.map((item) => ({
            label: item[label],
            value: item[value]
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const formatResultMultipleName = (
    datas,
    label = "name",
    a = "last_name",
    value = "id"
) => {
    return datas
        ?.map((item) => ({
            label: `${item[label]} ${item[a]}`,
            value: item[value]
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const formatResultWithout = (
    datas,
) => {
    return datas
        ?.map((item) => ({
            label: item,
            value: item
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const formatTableFirstColumn = (
    datas,
) => {
    return datas
        ?.map((item) => ({
            title: item,
            dataIndex: item,
            key:item,
            editable:true
        }))
};