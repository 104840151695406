import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spin, Steps, Tag, Timeline } from "antd";
import {
  BookOutlined,
  BellOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { GetUserData, get } from "../../helper";
import ForzaCard from "./Components/ForzaCard";
import ProfileCard from "./Components/ProfileCard";
import { NavigateButton } from "../../components";
import TimeTable from "./Components/TimeTable";
import PieChartComponent from "./Components/Chart";

const TeacherHomePage = () => {
  const [userInfo, setUserInfo] = useState(undefined);
  const [scheduleArray, setScheduleArray] = useState([]);
  const [homeworkArray, setHomeworkArray] = useState([]);
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const result = await get("dashboard");
    setHomeworkArray(result.data.homework);
    setScheduleArray(result.data.schedule);
    const announcementArr = [];
    result.data.announcement.map((item, i) =>
      announcementArr.push({
        color: i === 0 ? "orange" : i === 1 ? "blue" : "green",
        children: (
          <>
            <Row gutter={24} className="mt-20">
              <Col span={12}>
                <Row>
                  <Tag
                    color="#F5F5F5"
                    style={{
                      color: "black",
                      borderRadius: "20px",
                      padding: "2px 10px",
                      fontWeight: 600,
                    }}
                  >
                    {item.title}
                  </Tag>
                </Row>
                <Row style={{ fontWeight: 800, marginLeft: "10px" }}>
                  {item.end_date.split("T")[0]}
                </Row>
              </Col>
              <Col span={12} style={{ marginTop: "2px" }}>
                {item.date.split("T")[0]}
              </Col>
            </Row>
          </>
        ),
      })
    );
    setAnnouncementArray(announcementArr);
    const homeworkArr = [];
    result.data.homework.map((item, i) =>
      homeworkArr.push({
        color: i === 0 ? "orange" : i === 1 ? "blue" : "green",
        children: (
          <>
            <Row gutter={24} className="mt-20">
              <Col span={12}>
                <Row>
                  <Tag
                    color="#F5F5F5"
                    style={{
                      color: "black",
                      borderRadius: "20px",
                      padding: "2px 10px",
                      fontWeight: 600,
                    }}
                  >
                    {item.title}
                  </Tag>
                </Row>
                <Row style={{ fontWeight: 800, marginLeft: "10px" }}>
                  {item.end_date.split("T")[0]}
                </Row>
              </Col>
              <Col span={12} style={{ marginTop: "2px" }}>
                {item.date.split("T")[0]}
              </Col>
            </Row>
          </>
        ),
      })
    );
    setHomeworkArray(homeworkArr);

    setLoading(false);
  };

  const getUser = async () => {
    const result = await GetUserData();
    setUserInfo(result.data);
  };

  useEffect(() => {
    getData();
    getUser();
  }, []);

  return (
    <div>
      <Row gutter={24}>
        <Col span={18}>
          <Row gutter={24}>
            <Col span={16}>
              <ForzaCard userInfo={userInfo} />
            </Col>
            <Col span={8}>
              <ProfileCard userInfo={userInfo} />
            </Col>
          </Row>
          <Row gutter={24} className="mt-20 teacherHomePage">
            <Col span={12}>
              <Card
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ marginTop: "3px" }}>Ödevler</div>
                    <div>
                      <NavigateButton url={"/odevler"} />
                    </div>
                  </div>
                }
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <Timeline items={homeworkArray} />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ marginTop: "3px" }}>Duyurular</div>
                    <div>
                      <NavigateButton url={"/duyurular"} />
                    </div>
                  </div>
                }
              >
                <Timeline items={announcementArray} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <TimeTable scheduleArray={scheduleArray} />
        </Col>
      </Row>
    </div>
  );
};

export default TeacherHomePage;
