import Log from "./Log";
import ResetPassword from "./ResetPassword";
import UserInfo from "./UserInfo";

export const items = [
  {
    key: "1",
    label: "Profil Bilgileri",
    children: <UserInfo />,
  },
  {
    key: "2",
    label: "Şifre Değiştir",
    children: <ResetPassword />,
  },
  {
    key: "3",
    label: "Giriş Bilgileri",
    children: <Log />,
  },
];
