import React from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import { put } from "../../helper";

const ResetPassword = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const object = {
      old_password : values.old_password,
      new_password : values.new_password,
    }
    const result = await put("change-password",object);
    if (result.status === 200) message.success("Şifreniz Başarıyla Değiştirildi");
    else message.error("Hata oluştu");
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={6} push={9}>
          <p style={{ marginTop: "10px" }}>Şuanki şifrenizi giriniz</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12} push={6}>
          <Form.Item
            name="old_password"
            rules={[{ message: "Şifre giriniz", required: true }]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} push={9}>
          <p style={{ marginTop: "10px" }}>Yeni şifrenizi giriniz</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12} push={6}>
          <Form.Item
            name="new_password"
            rules={[{ message: "Şifre giriniz", required: true }]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} push={9}>
          <p style={{ marginTop: "10px" }}>Yeni şifrenizi doğrulayınız</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12} push={6}>
          <Form.Item
            name="confirm_password"
            dependencies={["new_password"]}
            rules={[
              {
                message: "Şifreyi doğrulayın",
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Şifre Eşleşmedi");
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        className="mt-10"
        onClick={() => form.submit()}
      >
        Şifre Değiştir
      </Button>
    </Form>
  );
};

export default ResetPassword;
