import React, { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Input, Modal, Row, Space, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ModalFooter from "./ModalFooter";
import { get, post } from "../helper";
import {
  PlusOutlined
} from '@ant-design/icons';

const Agreement = () => {
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [base64Files, setBase64Files] = useState([]);
  const [items, setItems] = useState([]);

  const defaultItems = [
    {
      key: 1,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.sihaotomasyon.com/static/ogrenci_sozlesme.pdf"
        >
          Öğrenci Sözleşmesi
        </a>
      ),
    },
    {
      key: 2,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.sihaotomasyon.com/static/ogretmen_sozlesme.pdf"
        >
          Öğretmen Sözleşmesi
        </a>
      ),
    }
  ];

  const getItems = async () => {
    const result = await get("agreement/");
    const arr = [];
    defaultItems.map(item => arr.push(item));
    result.data.detail.map((item,i) => arr.push({key:defaultItems.length+(i+1),label:(<a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://api.sihaotomasyon.com${item.file}`}
            >
              {item.name}
            </a>)}))
    arr.push({key:arr.length+1,label: <Button prefix={<PlusOutlined />} style={{backgroundColor:"orange", color:"white"}} onClick={() => setIsModalOpen(true)}>Sözleşme Ekle</Button>})
    setItems(arr);
  }

  const onFinish = async (values) => {
    base64Files.map((item) => values.file =  item.base64);
    const result = await post("agreement/",values);
    if(result.data.success) {
      setIsModalOpen(false);
      message.success("Sözleşme başarıyla eklendi");
    }
    else message.error("Sözleşme Eklenirken hata oluştu")
    getItems();
  };

  const handleOk = () => setIsModalOpen(false);

  const handleCancel = () => setIsModalOpen(false);
  
  const props = {
    onRemove: (file) => {
      const newBase64Files = base64Files.filter((item) => item.file !== file);
      setBase64Files(newBase64Files);
    },
    beforeUpload: async (file) => {
      try {
        const base64 = await convertToBase64(file);
        setBase64Files((prevBase64Files) => [
          ...prevBase64Files,
          { file, base64 },
        ]);
      } catch (error) {
        console.error("Base64 conversion error:", error);
        message.error("Dosya base64'e çevrilemedi.");
        return false;
      }
      return false;
    },
    fileList: [],
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    getItems();
  }, [])
  
  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <FileTextOutlined />
            Sözleşmeler
          </Space>
        </a>
      </Dropdown>
      <Modal
        title="Sözleşme Ekleme"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<ModalFooter form={form} handleCancel={handleCancel} />}
      >
        <Form form={form} name="basic" onFinish={onFinish}>
          <Form.Item label="Sözleşme Adı" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Eklenecek Sözleşme" name="file">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>İçerik Seç</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Agreement;
