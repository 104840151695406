import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin, Steps, Tag, Timeline } from "antd";
import {
  BookOutlined,
  BellOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { GetUserData, get } from "../../helper";
import ForzaCard from "./Components/ForzaCard";
import ProfileCard from "./Components/ProfileCard";
import { NavigateButton } from "../../components";
import TimeTable from "./Components/TimeTable";
import PieChartComponent from "./Components/Chart";
import announcementPng from "../../assets/media/announcement.png";

const StudentHomePage = () => {
  const [scheduleArray, setScheduleArray] = useState([]);
  const [homeworkArray, setHomeworkArray] = useState([]);
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [userInfo, setUserInfo] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const result = await get("dashboard");
    setHomeworkArray(result.data.homework);
    setScheduleArray(result.data.schedule);
    const arr = [];
    result.data.announcement.map((item, i) =>
      arr.push({
        color: i === 0 ? "orange" : i === 1 ? "blue" : "green",
        children: (
          <>
            <Row gutter={24} className="mt-20" style={{ lineHeight: "35px" }}>
              <Col span={12}>
                <Row>
                  <Tag
                    color="#F5F5F5"
                    style={{
                      color: "black",
                      borderRadius: "20px",
                      padding: "2px 10px",
                      fontWeight: 600,
                    }}
                  >
                    {item.title}
                  </Tag>
                </Row>
                <Row style={{ fontWeight: 800, marginLeft: "10px" }}>
                  {item.end_date.split("T")[0]}
                </Row>
              </Col>
              <Col span={12} style={{ marginTop: "2px", fontWeight: 600 }}>
                {item.date.split("T")[0]}
              </Col>
            </Row>
          </>
        ),
      })
    );
    setAnnouncementArray(arr);
    setLoading(false);
  };

  const getUser = async () => {
    const result = await GetUserData();
    setUserInfo(result.data);
  };

  useEffect(() => {
    getData();
    getUser();
  }, []);

  return loading ? (
    <Spin />
  ) : (
    <Row gutter={24}>
      <Col span={18}>
        <Row gutter={24}>
          <Col span={16}>
            <ForzaCard userInfo={userInfo} />
          </Col>
          <Col span={8}>
            <ProfileCard userInfo={userInfo} />
          </Col>
        </Row>
        <Row gutter={24} className="mt-20 teacherHomePage">
          <Col span={12}>
            <Card
              style={{ minHeight: "325px", height:"5vh" }}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginTop: "3px" }}>Ödevler</div>
                  <div>
                    <NavigateButton url={"/odevler"} />
                  </div>
                </div>
              }
            >
              <Row gutter={24}>
                <Col span={14}>
                  <PieChartComponent
                    data={[
                      { name: "Matematik", count: 10 },
                      { name: "Fizik", count: 15 },
                      { name: "Türkçe", count: 7 },
                    ]}
                  />
                </Col>
                <Col span={10}>
                  {homeworkArray.map((item, i) => (
                    <Card
                      key={i}
                      style={{
                        backgroundColor: "#F5F5F5",
                        marginTop: i === 0 ? 0 : "3px",
                        height: "80px",
                      }}
                    >
                      <Row style={{ fontSize: "13px" }}>{item.title}</Row>
                      <Row style={{ fontSize: "13px" }}>
                        {item.end_date.split("T")[0]}
                      </Row>
                    </Card>
                  ))}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              style={{ minHeight: "327px", height:"5vh" }}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginTop: "3px" }}>Duyurular</div>
                  <div>
                    <NavigateButton url={"/duyurular"} />
                  </div>
                </div>
              }
            >
              <div style={{ position: "absolute", bottom: 0, right: 10 }}>
                <img
                  src={announcementPng}
                  alt="Fotoğraf"
                  style={{ width: "250px", height: "250px", opacity: 0.2 }}
                />
              </div>
              <Timeline items={announcementArray} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <TimeTable scheduleArray={scheduleArray} type="Student" />
      </Col>
    </Row>
  );
};

export default StudentHomePage;
