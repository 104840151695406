import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Performance from "./Performance";
import { GetUserData } from "../../helper";
import {items} from "./Constant";

const Page = () => {
  const [type, setType] = useState(undefined);

  const defaultComponent = <Performance />;

  const personelComponent = (
    <Tabs defaultActiveKey="1" type="card" items={items} centered />
  );

  const renderedComponent =
    type === "Genel Personel" ? personelComponent : defaultComponent;

  const getUser = async () => {
    const result = await GetUserData();
    setType(result.data.role);
  };

  useEffect(() => {
    getUser();
  }, []);

  return renderedComponent;
};

export default Page;
