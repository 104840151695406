import { Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { CustomNumberInput, ModalFooter } from "../../../components";
import { formatResultWith, get, post, put } from "../../../helper";
import { teacherContinue } from "../../../services";

const AddTeacher = ({ getData, setIsModalOpen, isModalOpen, info }) => {
  const [form] = Form.useForm();

  const [lessonArray, setLessonArray] = useState([]);

  const onFinish = async (values) => {
    const url = info ? `${teacherContinue}${info._id}` : teacherContinue;
    const result = await (info ? put(url, values) : post(url, values));
    if (result.status === 200) {
      getData();
      setIsModalOpen(false);
      message.success(
        info
          ? "Öğretmen düzenleme işlemi başarılı"
          : "Öğretmen ekleme işlemi başarılı"
      );
    } else message.error("İşlem Başarısız");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => setIsModalOpen(false);

  const getLesson = async () => {
    const result = await get("lesson/");
    setLessonArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  useEffect(() => {
    if (info) {
      const lessArr = [];
      info.lesson.map(item => lessArr.push(item._id))
      info.tckn = info.tckn.toString();
      form.setFieldsValue(info);
      form.setFieldsValue({ lesson_id: lessArr });
    } else form.resetFields();
    getLesson();
  }, [info]);

  return (
    <Modal
      title={info ? "Öğretmen Düzenle" : "Öğretmen Kaydet"}
      open={isModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="İsim"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Soyisim"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Cinsiyet"
          name="gender"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <Select
            options={[
              {label:"Erkek",value:"Erkek"},
              {label:"Kadın",value:"Kadın"}
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Kimlik No"
          name="tckn"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
            },
            {
              max: 11,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
              min: 11,
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={11} />
        </Form.Item>

        <Form.Item
          label="Telefon No"
          name="phone"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={10} prefix="+90" />
        </Form.Item>

        {info && (
          <Form.Item
            label="Şifre"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}

        <Form.Item
          label="Dersler"
          name="lesson_id"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            optionFilterProp="children"
            options={lessonArray}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTeacher;
