import React, { useEffect, useState } from "react";
import { GetUserData, formatResultWith, post } from "../../helper";
import { Col, Row } from "antd";
import HomeworkCard from "./HomeworkCard";
import Filter from "./Filter";
import PaginationComp from "./Pagination";
import TeacherAddHomework from "./TeacherAddHomework";
import GeneralFilter from "./GeneralFilter";
import GeneralHomeworkArea from "./GeneralHomeworkArea";
import { useLocation } from "react-router-dom";
import AnnouncementCard from "./AnnouncementCard";
import AddHomeworkAnnouncement from "./AddHomeworkAnnouncement";
import GeneralAnnouncementArea from "./GeneralAnnouncementArea";

const Page = () => {
  const location = useLocation();
  const key = location.pathname.split("/")[1];

  const [type, setType] = useState();
  const [homeworkAnnouncementArray, setHomeworkAnnouncementArray] = useState(
    []
  );
  const [lessonsFilterArray, setLessonsFilterArray] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [teacherLessonArray, setTeacherLessonArray] = useState([]);
  const [info, setInfo] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({
    page_size: 10,
    page: currentPage,
    keyword: "",
    sort: "_id",
    lesson_id: [],
    class_id: [],
  });

  const getUser = async () => {
    try {
      const result = await GetUserData();
      if (result.data.role === "Student") {
        setLessonsFilterArray(result.data.class_id.lessons);
      } else if (result.data.role === "Parent") {
        setLessonsFilterArray(result.data.children[0].class_id.lessons);
      } else if (result.data.role === "Teacher") {
        const teacherLessArr = formatResultWith(
          result.data.lesson_id,
          "name",
          "id"
        );
        setTeacherLessonArray(teacherLessArr);
        setLessonsFilterArray(teacherLessArr);
      } else {
        setLessonsFilterArray(undefined);
      }
      setType(result.data.role);
    } finally {
      getMessage();
    }
  };

  const getMessage = async () => {
    const result = await post(
      key === "odevler" ? "message/homework" : "message/announcement",
      query
    );
    result.data.detail.map((item, i) => (item.key = i));
    setTotalPageCount(result.data.total_count);
    setHomeworkAnnouncementArray(result.data.detail);
  };

  useEffect(() => {
    getUser();
  }, [key]);

  useEffect(() => {
    getMessage();
  }, [query, currentPage]);


  return (
    <div className="homeworkComp">
    {type === "Teacher" && (
      <Row justify="space-between">
        <Col />
        <Col>
          <TeacherAddHomework
            setIsAddModalOpen={setIsAddModalOpen}
            pathKey={key}
          />
        </Col>
      </Row>
    )}
    <Row gutter={24}>
      <Col span={6}>
        {type === "Genel Personel" ? (
          <GeneralFilter setQuery={setQuery} key={key} />
        ) : (
          <Filter
            type={type}
            lessonsFilterArray={lessonsFilterArray}
            setQuery={setQuery}
            key={key}
          />
        )}
      </Col>
      <Col span={18}>
        {key === "odevler" && (
          <HomeworkCard
            type={type}
            homeworkArray={homeworkAnnouncementArray}
            setIsAddModalOpen={setIsAddModalOpen}
            setInfo={setInfo}
            getMessage={getMessage}
          />
        )}
        {key === "duyurular" && (
          <AnnouncementCard
            type={type}
            announcementArray={homeworkAnnouncementArray}
            getMessage={getMessage}
            setIsAddModalOpen={setIsAddModalOpen}
            setInfo={setInfo}
          />
        )}
      </Col>
    </Row>
    <Row gutter={24} className="mt-20">
      <Col span={6} />
      <Col span={18}>
        <PaginationComp totalCount={totalPageCount} />
      </Col>
    </Row>
    <AddHomeworkAnnouncement
      setIsAddModalOpen={setIsAddModalOpen}
      isAddModalOpen={isAddModalOpen}
      teacherLessonArray={teacherLessonArray}
      getMessage={getMessage}
      info={info}
      pathKey={key}
    />
  </div> 
  );
};

export default Page;
