import { Tabs } from "antd";
import React from "react";
import { items } from "./Constant";

const Page = () => {

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      items={items}
      centered
    />
  );
};

export default Page;
