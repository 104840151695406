import Factors from "./Factors";
import Performance from "./Performance";

export const items = [
    {
      key: "1",
      label: "Senet Performansı",
      children: <Performance />,
    },
    {
      key: "2",
      label: "Performans Etkenleri",
      children: <Factors />,
    },
  ];