import React, { useEffect, useState } from "react";
import { GetUserData } from "../../helper";
import { Card, Col, Row } from "antd";

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState({});

  const getUser = async () => {
    try {
      const result = await GetUserData();
      setUserInfo(result.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="userInfo">
      <Row gutter={24}>
        <Col span={8} style={{ marginTop: "30vh" }}>
          Fotoğraf Alanı
        </Col>
        <Col span={16}>
          <Card
            style={{ backgroundColor: "gray", color: "white" }}
            className="mt-20"
          >
            <Row gutter={24}>
              <Col span={8}>Ad</Col>
              <Col span={1}>:</Col>
              <Col span={15}>{userInfo?.first_name}</Col>
            </Row>
          </Card>
          <Card className="mt-20">
            <Row gutter={24}>
              <Col span={8}>Soyad</Col>
              <Col span={1}>:</Col>
              <Col span={15}>{userInfo?.last_name}</Col>
            </Row>
          </Card>

          {userInfo.role === "Teacher" && (
            <Card
              className="mt-20"
              style={{ backgroundColor: "gray", color: "white" }}
            >
              <Row gutter={24}>
                <Col span={8}>Dersler</Col>
                <Col span={1}>:</Col>
                <Col span={15}>{userInfo?.lesson_id?.map(item => <span>{`${item.name} `}</span>)}</Col>
              </Row>
            </Card>
          )}

          {userInfo.role === "Student" && (
            <>
              {" "}
              <Card
                className="mt-20"
                style={
                  userInfo.role === "Student"
                    ? { backgroundColor: "gray", color: "white" }
                    : undefined
                }
              >
                <Row gutter={24}>
                  <Col span={8}>Sınıf</Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>{userInfo?.class_id?.name}</Col>
                </Row>
              </Card>
              <Card className="mt-20">
                <Row gutter={24}>
                  <Col span={8}>Şube</Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>{userInfo?.section_id?.name}</Col>
                </Row>
              </Card>
            </>
          )}

          <Card
            className="mt-20"
            style={
              userInfo.role === "Student" || userInfo.role === "Genel Personel" || userInfo.role === "Parent"
                ? { backgroundColor: "gray", color: "white" }
                : undefined
            }
          >
            <Row gutter={24}>
              <Col span={8}>Tc No</Col>
              <Col span={1}>:</Col>
              <Col span={15}>{userInfo?.tckn}</Col>
            </Row>
          </Card>
          <Card
            className="mt-20"
            style={
              userInfo.role === "Teacher"
                ? { backgroundColor: "gray", color: "white" }
                : undefined
            }
          >
            <Row gutter={24}>
              <Col span={8}>Telefon No</Col>
              <Col span={1}>:</Col>
              <Col span={15}>{userInfo?.phone}</Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserInfo;
