import React from "react";
import { Card, Carousel, Col, Row } from "antd";
import LoginArea from "../components/LoginArea";
import car1 from "../assets/media/1.png";
import car2 from "../assets/media/2.png";
import car3 from "../assets/media/3.png";
import car4 from "../assets/media/4.png";

const LoginPage = ({ setLoginAvailable }) => {
  const imgArray = [car1, car2, car3, car4];
  return (
    <div className="loginComp" style={{overflow:"hidden"}}>
      <Card bordered={false} style={{ height: "auto" }}>
        <Row gutter={24}>
          <Col xs={0} sm={24} md={24} lg={12}>
            <Carousel autoplay>
              {imgArray.map((item, index) => (
                <div key={index}>
                  <img
                    src={item}
                    alt={`Slide ${index + 1}`}
                    className="carouselImg"
                  />
                </div>
              ))}
            </Carousel>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row gutter={24} className="loginFormArea">
              <Col span={24}>
                <h1 className="loginH1" style={{textAlign:"center"}}>SIHA Otomasyon</h1>
                <h2 className="loginH2" style={{textAlign:"center"}}>Hoşgeldiniz!</h2>
              </Col>
              <Col span={24}>
                <LoginArea setLoginAvailable={setLoginAvailable} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default LoginPage;
