import React, { useState } from "react";
import { Pagination } from "antd";

const PaginationComp = ({ currentPage, setCurrentPage, totalCount }) => {
  

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Önceki</a>;
    }
    if (type === "next") {
      return <a>Sonraki</a>;
    }
    return originalElement;
  };

  const onChange = (e) => setCurrentPage(e);

  return (
    <Pagination
      current={currentPage}
      total={totalCount}
      itemRender={itemRender}
      onChange={onChange}
    />
  );
};

export default PaginationComp;
