export const formatDate = (tarihStr) => {
  const tarih = new Date(tarihStr);
  const gun = tarih.getDate();
  const ayNumarasi = tarih.getMonth();
  const yil = tarih.getFullYear();
  const aylar = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const ay = aylar[ayNumarasi];
  return gun + " " + ay + " " + yil;
};
