export const columns = [
  {
    title: "Öğrenci No",
    dataIndex: "student_no",
    key: "student_no",
    align: "center",
  },
  {
    title: "İsim",
    dataIndex: "first_name",
    key: "first_name",
    align: "center",
  },
  {
    title: "Soyisim",
    dataIndex: "last_name",
    key: "last_name",
    align: "center",
  },
];
