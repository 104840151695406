import { Button, Col, Form, Input, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { get, post } from "../../helper";

const Factors = () => {
  const [form] = Form.useForm();

  const [data,setData] = useState([]);

  const onFinish = async (values) => {
    const arr = [
      {
        discount: values.f1,
        percentage: values.f2,
      },
      {
        discount: values.s1,
        percentage: values.s2,
      },
      {
        discount: values.t1,
        percentage: values.t2,
      },
      {
        discount: values.fo1,
        percentage: values.fo2,
      },
    ];
    const filterArray = [];
    arr.filter((obje) =>
      Object.keys(obje).map((item, i) =>
        obje[item] !== undefined
          ? filterArray.push(
              i === 0 ? { discount: obje[item] } : { percentage: obje[item] }
            )
          : undefined
      )
    );
    let lastData = [];
    for (let i = 0; i < filterArray.length; i += 2) {
      let yeniEleman = {};
      if (filterArray[i] && filterArray[i]["discount"] !== "") {
        yeniEleman["discount"] = filterArray[i]["discount"];
      }
      if (filterArray[i + 1] && filterArray[i + 1]["percentage"] !== "") {
        yeniEleman["percentage"] = filterArray[i + 1]["percentage"];
      }
      if (Object.keys(yeniEleman).length > 0) {
        lastData.push(yeniEleman);
      }
    }
    const result = await post("payment/performance", lastData);
    if (result.data.success)
      message.success("Başarı Etkenleri başarıyla oluşturuldu");
    else message.error("Hata oluştu");
  };

  const getPerformance = async () => {
    const result = await get("payment/performance");
    setData(result.data);
    if (result.data && result.data.length > 0) {
      result.data.forEach((item, index) => {
        form.setFieldsValue({
          [`${index + 1}1`]: item.discount,
          [`${index + 1}2`]: item.percentage,
        });
      });
    }
  };

  useEffect(() => {
    getPerformance();
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col span={10} push={6}></Col>
        <p>Performans Etkenleri</p>
      </Row>
      <Form name="basic" form={form} onFinish={onFinish}>
      <Row gutter={24}>
        {Array.from({ length: Math.ceil(data.length / 2) }, (_, rowIndex) => (
          <Col span={12} key={rowIndex}>
            <Row gutter={24} className="mt-20">
              {Array.from({ length: 2 }, (_, colIndex) => (
                <Col span={12} key={colIndex}>
                  <Form.Item name={`${rowIndex * 2 + colIndex + 1}1`}>
                    <Input prefix="%" placeholder="İndirim oranı" />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row gutter={24} className="mt-20">
              {Array.from({ length: 2 }, (_, colIndex) => (
                <Col span={12} key={colIndex}>
                  <Form.Item name={`${rowIndex * 2 + colIndex + 1}2`}>
                    <Input placeholder="Başarı Oranı" />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
      <Row gutter={24} className="mt-30">
        <Col span={4} push={10}>
          <Form.Item>
            <Button htmlType="submit">Kaydet</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
    </>
  );
};

export default Factors;
