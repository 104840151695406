import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Space } from "antd";
import { logoIcon } from "../assets/media";
import Profile from "./Profile";
import Agreement from "./Agreement";
import { useNavigate } from "react-router-dom";
import { GetUserData } from "../helper";
const { Header } = Layout;

const HeaderLayout = ({ setLoginAvailable }) => {
  const navigate = useNavigate();

  const [type, setType] = useState();

  const getUser = async () => {
    const result = await GetUserData();
    setType(result.data.role);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Header className="header">
      <Row justify="space-between" align="middle">
        <Col className="mt-10" style={{ marginLeft: "-29px" }}>
          <img
            src={logoIcon}
            alt="Logo"
            style={{ width: "auto", height: "50px", cursor: "pointer" }}
            onClick={() => navigate("anaSayfa")}
          />
        </Col>
        <Col className="headerRight" style={{ fontSize: "14px" }}>
          <Space size="middle">
            <nav >
              <img />
              Kılavuz
            </nav>
            {type === "Genel Personel" && <Agreement />}
            <Profile setLoginAvailable={setLoginAvailable} />
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderLayout;
