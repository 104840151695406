import React, { useState, useEffect } from "react";
import { DatePicker, Form, Input, Modal, Select, message } from "antd";
import { ModalFooter } from "../../components";
import { formatResultMultipleName, get, post, put } from "../../helper";
import locale from "antd/es/date-picker/locale/tr_TR";
import "moment/locale/tr";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY/MM/DD";

const { RangePicker } = DatePicker;

const AddAssignment = ({
  isModalOpen,
  setIsModalOpen,
  getAssignments,
  info,
}) => {
  const [form] = Form.useForm();

  const [teacherArray, setTeacherArray] = useState([]);

  const handleOk = () => setIsModalOpen(false);

  const handleCancel = () => setIsModalOpen(false);

  const onFinish = async (values) => {
    const object = { ...values };
    delete object.date;
    if (info && values.date === undefined) {
      const first = info.start_date.split(".");
      const second = info.end_date.split(".");
      object.start_date = `${first[2]}-${first[1]}-${first[0]}T10:44:39`;
      object.end_date = `${second[2]}-${second[1]}-${second[0]}T10:44:39`;
    } else if (info && values.date !== undefined) {
      const first = new Date(values.date[0]);
      const second = new Date(values.date[1]);
      const firstUTC = new Date(
        first.getTime() + first.getTimezoneOffset() * 60 * 1000
      );
      const secondUTC = new Date(
        second.getTime() + second.getTimezoneOffset() * 60 * 1000
      );
      firstUTC.setDate(firstUTC.getDate() + 1);
      secondUTC.setDate(secondUTC.getDate() + 1);
      object.start_date = firstUTC.toISOString();
      object.end_date = secondUTC.toISOString();
    } else {
      const formatDateToISOString = (date) => {
        const offset = date.getTimezoneOffset() * 60000;
        const localTime = date.getTime() - offset;
        return new Date(localTime).toISOString();
      };
      const first = new Date(values.date[0]);
      const second = new Date(values.date[1]);
      object.start_date = formatDateToISOString(first);
      object.end_date = formatDateToISOString(second);
    }
    object.status = info ? info.status : "given";
    const result = await (info
      ? put(`task/${info._id}`, object)
      : post("task/", object));
    if (result.data.success)
      message.success(
        info ? "Görev Başarıyla Güncellendi" : "Görev Başarıyla Eklendi"
      );
    else
      message.error(
        info
          ? "Görev Güncellenirken hata oluştu"
          : "Görev Eklenirken hata oluştu"
      );
    getAssignments();
    setIsModalOpen(false);
  };

  const getTeacher = async () => {
    const result = await get("teacher/");
    setTeacherArray(
      formatResultMultipleName(
        result.data.detail,
        "first_name",
        "last_name",
        "_id"
      )
    );
  };

  useEffect(() => {
    form.resetFields();
    if (info !== undefined) {
      form.setFieldsValue(info);
      form.setFieldsValue({ teacher_id: info.teacher_id.id });
    }
    getTeacher();
  }, [info]);

  return (
    <Modal
      title={info ? "Görev Düzenle" : "Görev Ekle"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
    >
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Öğretmen"
          name="teacher_id"
          rules={[
            {
              required: true,
              message: "Lütfen öğretmen seçiniz!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            options={teacherArray}
            placeholder="Öğretmen Seçiniz"
          />
        </Form.Item>
        <Form.Item
          label="Görev Başlığı"
          name="title"
          rules={[
            {
              required: true,
              message: "Lütfen görev başlığı giriniz!",
            },
          ]}
        >
          <Input placeholder="Görev başlığı giriniz" />
        </Form.Item>
        <Form.Item
          label="Görev İçeriği"
          name="content"
          rules={[
            {
              required: true,
              message: "Lütfen görev içeriği giriniz!",
            },
          ]}
        >
          <Input placeholder="Görev açıklaması giriniz" />
        </Form.Item>
        <Form.Item label="Başlangıç - Bitiş Tarihi" name="date">
          <RangePicker
            defaultValue={
              info
                ? [
                    dayjs(
                      `${info?.end_date?.split(".")?.at(2)}/${info?.end_date
                        ?.split(".")
                        ?.at(1)}/${info?.end_date?.split(".")?.at(0)}`,
                      dateFormat
                    ),
                    dayjs(
                      `${info?.start_date?.split(".")?.at(2)}/${info?.start_date
                        ?.split(".")
                        ?.at(1)}/${info?.start_date?.split(".")?.at(0)}`,
                      dateFormat
                    ),
                  ]
                : undefined
            }
            locale={locale}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAssignment;
