import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PieChartComponent from "./Components/Chart";
import { GetUserData, get } from "../../helper";
import BillCard from "./Components/BillCard";
import ForzaCard from "./Components/ForzaCard";
import ProfileCard from "./Components/ProfileCard";
import GenderChart from "./Components/GenderChart";
import CalendarCard from "./Components/CalendarCard";
import RollCallCard from "./Components/RollCallCard";

const GeneralHomePage = () => {
  const [data, setData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [paymentsArray, setPaymentsArray] = useState([]);
  const [userInfo, setUserInfo] = useState(undefined);

  const getData = async () => {
    const result = await get("dashboard");

    if (result.status === 200) {
      setPaymentsArray(result.data.last_three_payments);
      setData(result.data.counts);
      setGenderData(result.data.students_counts);
    } else message.error("Hata Oluştu");
  };

  const getUser = async () => {
    const result = await GetUserData();
    setUserInfo(result.data);
  };

  useEffect(() => {
    getData();
    getUser();
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={18}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={18}>
              <ForzaCard userInfo={userInfo} />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <ProfileCard userInfo={userInfo} />
            </Col>
          </Row>
          <Row gutter={24} className="mt-10">
            <Col xs={24} sm={24} md={12}>
              <CalendarCard />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <RollCallCard />
              <Row gutter={24} className="mt-10">
                <Col xs={24}>
                  <BillCard paymentsArray={paymentsArray} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
          <Row gutter={24}>
            <Col xs={24}>
              <PieChartComponent data={data} />
            </Col>
          </Row>
          <Row gutter={24} className="mt-10">
            <Col xs={24}>
              <GenderChart data={genderData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default GeneralHomePage;
