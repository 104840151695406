import React, { useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  message,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import { studentContinue } from "../../../services";
import { formatResultMultipleName, get, putWithQuery } from "../../../helper";
import "moment/locale/tr";

const { RangePicker } = DatePicker;

const Page = () => {
  const [form] = Form.useForm();

  const [studentArray, setStudentArray] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onSearchStudentSelect = async (e) => {
    const inputLength = e.length;
    if (inputLength >= 3) {
      const result = await get(studentContinue, null, { keyword: e });
      setStudentArray(
        formatResultMultipleName(
          result.data.detail,
          "first_name",
          "last_name",
          "_id"
        )
      );
    } else
      setStudentArray([
        { label: "En az 3 harf girin", value: 1, disabled: true },
      ]);
  };

  const onFinish = async (values) => {
    const first = new Date(values.date[0].$d);
    const second = new Date(values.date[1].$d);
    const query = {
      start_date: first.toISOString().split(".")[0],
      end_date: second.toISOString().split(".")[0],
    };
    const result = await putWithQuery(
      `student/excuse/${values.type}/${values.student_id}`,
      query
    );
    if (result.status === 200)
      message.success("Raporlu/İzinli durumu başarıyla oluşturuldu");
    else message.error("Hata oluştu");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="mt-20">
      <Form
        form={form}
        name="basic"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Öğrenci"
              name="student_id"
              rules={[
                {
                  required: true,
                  message: "Lütfen öğrenci seçiniz",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                options={studentArray}
                placeholder="Öğrencinin adını girin (en az 3 karakter)"
                filterOption={filterOption}
                onSearch={onSearchStudentSelect}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Başlangıç - Bitiş Tarihi"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Lütfen başlangıç ve bitiş tarihlerini seçiniz",
                },
              ]}
            >
              <RangePicker style={{width:"100%"}} locale={locale} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <Form.Item
              label="Durum"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Lütfen durum seçiniz",
                },
              ]}
            >
              <Radio.Group onChange={handleRadioChange} value={selectedOption}>
                <Radio value="Raporlu">Raporlu</Radio>
                <Radio value="İzinli">İzinli</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} push={6}>
            <Form.Item>
              <Button htmlType="submit" style={{backgroundColor:"#7365FF" , color:"white"}}>Gönder</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Page;
