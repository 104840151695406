import React from "react";
import { Card, Col, Row } from "antd";
import title from "../../../assets/media/forzaCardTitle.png";
import clockGif from "../../../assets/media/clock.gif";

const ForzaCard = ({ userInfo }) => {
  return (
    <Card
      className="gradientBackground"
      style={{ borderRadius: "20px", height: "150px", color: "white" }}
    >
      <Row gutter={24}>
        <Col xs={16} md={16} lg={18}>
          <Row gutter={24}>
            <Col
              span={24}
              style={{
                fontSize: "23px",
                textAlign: "left",
                fontWeight: 700,
                marginTop: "-30px",
                marginBottom: "-15px",
                letterSpacing: "1px",
              }}
            >
              <img src={title} className="forzaCardImg" />
              <p className="bs">
                Merhaba
                <span style={{ color: "#2AA9E1", marginLeft: "5px" }}>
                  {userInfo?.first_name}
                </span>
                <span style={{ color: "#2AA9E1", marginLeft: "5px" }}>
                  {userInfo?.last_name},
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mt-20 para">
            <p>
              Eğitimin her aşamasında bir adım önde olmanın keyfini yaşayın!{" "}
              <br />
              Yönetimden öğrencilere, öğretmenlerden velilere herkes için bir
              araya getirdiğimiz pratik çözümlerle dolu bir deneyim sizi
              bekliyor.
            </p>
          </Row>
        </Col>
        <Col xs={8} md={8} lg={6}>
          <img className="forzaCardGif" src={clockGif} alt="GIF" />
        </Col>
      </Row>
    </Card>
  );
};

export default ForzaCard;
