import React from "react";

const Page = () => {
  return (
    <div >
      <video controls width="90%" height="auto" style={{borderRadius:"20px"}}>
        <source
          src="https://bucket.enesdogan.dev/video5996741971283022611.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Page;
