import React, { useEffect, useState } from "react";
import { GetUserData, get } from "../../helper";
import AddOpinion from "./AddOpinion";
import OpinionCard from "./OpinionCard";
import Filter from "./Filter";
import { Button, Col, Row, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const Page = () => {
  const [type, setType] = useState();
  const [loading, setLoading] = useState(true);
  const [opinionArray, setOpinionCard] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [info, setInfo] = useState(undefined);
  const [opinionType, setOpinionType] = useState(undefined);

  const getOpinion = async () => {
    const result = await get("student/notes");
    setOpinionCard(result.data);
  };

  const getUser = async () => {
    setLoading(true);
    const result = await GetUserData();
    setType(result.data.role);
    if (result.data.role === "Parent")
      setOpinionCard(result.data.children[0].teacher_notes);
    else if (result.data.role === "Teacher") getOpinion();
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        type === "Teacher" && (
          <Row justify="space-between">
            <Col />
            <Col>
            <Button
                style={{
                  marginBottom: "5px",
                  marginRight:"5px",
                  backgroundColor: "#7365FE",
                  color: "white",
                }}
                icon={<PlusOutlined />}
                onClick={() => [setIsModalOpen(true), setInfo(undefined), setOpinionType("parentType")]}
              >
                Yeni Veli Görüşmesi Ekle
              </Button>
              <Button
                style={{
                  marginBottom: "5px",
                  backgroundColor: "#7365FE",
                  color: "white",
                }}
                icon={<PlusOutlined />}
                onClick={() => [setIsModalOpen(true), setInfo(undefined), setOpinionType("teacherType")]}
              >
                Yeni Görüş Ekle
              </Button>
            </Col>
          </Row>
        )
      )}
      <Row gutter={24}>
        <Col span={6} className="mt-20">
          <Filter />
        </Col>
        <Col span={18}>
          <OpinionCard
            type={type}
            opinionArray={opinionArray}
            getUser={getUser}
            setInfo={setInfo}
            setIsModalOpen={setIsModalOpen}
          />
        </Col>
      </Row>
      <AddOpinion
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        info={info}
        getUser={getUser}
        opinionType={opinionType}
      />
    </div>
  );
};

export default Page;
