import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import {
  formatResultWith,
  formatResultWithout,
  get,
  post,
  put,
} from "../../../helper";
import AddBranchLesson from "./AddBranchLesson";
import {
  classContinue,
  grades,
  lessonContinue,
  levels,
  sectionContinue,
} from "../../../services";

const AddClass = ({
  setIsModalOpen,
  isModalOpen,
  info = undefined,
  getData,
}) => {
  const [form] = Form.useForm();

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [levelArray, setLevelArray] = useState([]);
  const [gradeArray, setGradeArray] = useState([]);
  const [lessonArray, setLessonArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);
  const [gradeValue, setGradeValue] = useState();
  const [sectionLessonType, setSectionLessonType] = useState();

  const onFinish = async (values) => {
    const arr = [];
    values.sections.map(item => {
      if(item.value !== undefined) {
        arr.push(item.value);
        values.sections = arr;
      }
    });
    const url = info ? `${classContinue}${info._id}` : classContinue;
    const result = await (info ? put(url, values) : post(url, values));
    if (result.status === 200) {
      message.success("Başarıyla kayıt edildi");
      setIsModalOpen(false);
      getData();
    } else message.error("Kayıt oluşturma başarısız");
  };

  const handleOk = () => setIsModalOpen(false);

  const handleCancel = () => setIsModalOpen(false);

  const onClickBranch = (e) => {
    setSectionLessonType(e);
    setIsModalOpen1(true);
  };

  const getSectionData = async () => {
    const result = await get(sectionContinue);
    setSectionArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const getLevelData = async () => {
    const result = await get(`${classContinue}${levels}`);
    setLevelArray(formatResultWithout(result.data));
  };

  const onChangeLevel = async (e) => {
    const result = await get(`${classContinue}${grades}`, null, {
      level: e,
    });
    setGradeArray(
      formatResultWithout(result.data).sort(function (a, b) {
        return a.value - b.value;
      })
    );
  };

  const onChangeGrade = async (e = gradeValue) => {
    setGradeValue(e);
    const result = await get(lessonContinue, null, {
      grade: e,
    });
    const arr = [];
    result.data.detail.map((item) => arr.push(item._id));
    form.setFieldsValue({
      lessons:
        info?.lessons?.length === 0
          ? undefined
          : info?.lessons?.length >= 1
          ? info.lessons.map((item) => item.id)
          : arr,
    });
    setLessonArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  useEffect(() => {
    getLevelData();
    getSectionData();
    if (info) {
      onChangeLevel(info.level);
      onChangeGrade(info.grade);
      const object = { ...info };
      object.lessons = formatResultWith(info.lessons, "name", "id");
      object.sections = formatResultWith(info.sections, "name", "id");
      form.setFieldsValue(object);
    } else form.resetFields();
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal
        title={info ? "Sınıf Düzenle" : "Yeni Sınıf Oluştur"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button className="closeBtnModal" icon={<CloseOutlined />}>
            Kapat
          </Button>,
          <Button
            className="saveBtnModal"
            icon={<CheckOutlined />}
            onClick={() => form.submit()}
          >
            Kaydet
          </Button>,
        ]}
      >
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={24} className="mt-20">
            <Col span={6}>Sınıf Adı</Col>
            <Col span={18}>
              <Form.Item name="name">
                <Input placeholder="Sınıf Adı Giriniz..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>Üst Düzey</Col>
            <Col span={18}>
              <Form.Item name="level">
                <Select
                  showSearch
                  onChange={onChangeLevel}
                  placeholder="Üst Düzey Seçiniz"
                  optionFilterProp="children"
                  allowClear
                  filterOption={filterOption}
                  options={levelArray}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>Düzey</Col>
            <Col span={18}>
              <Form.Item name="grade">
                <Select
                  showSearch
                  onChange={onChangeGrade}
                  placeholder="Düzey Seçiniz"
                  optionFilterProp="children"
                  allowClear
                  options={gradeArray}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>Dersler</Col>
            <Col span={15}>
              <Form.Item name="lessons">
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  options={lessonArray}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button onClick={() => onClickBranch("lesson")}>+</Button>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>Şubeler</Col>
            <Col span={15}>
              <Form.Item name="sections">
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  options={sectionArray}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button onClick={() => onClickBranch("section")}>+</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {isModalOpen1 && (
        <AddBranchLesson
          setIsModalOpen1={setIsModalOpen1}
          isModalOpen1={isModalOpen1}
          sectionLessonType={sectionLessonType}
          getData={getData}
          onChangeGrade={onChangeGrade}
          getSectionData={getSectionData}
        />
      )}
    </>
  );
};

export default AddClass;
