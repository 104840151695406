import { Form, Input, Modal, Select, message } from "antd";
import React, { useEffect } from "react";
import { CustomNumberInput, ModalFooter } from "../../../components";
import { post, put } from "../../../helper";
import { employeeContinue } from "../../../services";

const AddEmployee = ({ getData, setIsModalOpen, isModalOpen, info }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const url = info ? `${employeeContinue}${info._id}` : employeeContinue;
    const result = await (info ? put(url, values) : post(url, values));
    if (result.status === 200) {
      getData();
      setIsModalOpen(false);
      message.success(
        info
          ? "Personel düzenleme işlemi başarılı"
          : "Personel ekleme işlemi başarılı"
      );
    } else message.error("İşlem Başarısız");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (info) {
      info.tckn = info.tckn.toString();
      form.setFieldsValue(info);
    } else form.resetFields();
  }, [info]);

  return (
    <Modal
      title={info ? "Personel Düzenle" : "Personel Kaydet"}
      open={isModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="İsim"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Soyisim"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          label="Cinsiyet"
          name="gender"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <Select
            options={[
              {label:"Erkek",value:"Erkek"},
              {label:"Kadın",value:"Kadın"}
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Kimlik No"
          name="tckn"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
            },
            {
              max: 11,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
              min: 11,
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={11} />
        </Form.Item>

        <Form.Item
          label="Telefon No"
          name="phone"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={10} prefix="+90" />
        </Form.Item>

        <Form.Item
          label="Rol"
          name="role"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="children"
            options={[
              {
                label: "Genel Personel",
                value: "Genel Personel",
              },
              {
                label: "Raporlama",
                value: "Raporlama",
              },
              {
                label: "Sistem Personel Yetkisiz",
                value: "Sistem Personel Yetkisiz",
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEmployee;
