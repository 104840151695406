import { Button, Card, Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { formatResultWith, get } from "../../helper";
import { classContinue, lessonContinue } from "../../services";

const GeneralFilter = ({setQuery, key}) => {
  const [classFilterArray, setClassFilterArray] = useState([]);
  const [lessonsFilterArray, setLessonsFilterArray] = useState([]);
  const [checkedClassValues, setCheckedClassValues] = useState([]);
  const [checkedLessonValues, setCheckedLessonValues] = useState([]);

  const getClassData = async () => {
    const result = await get(classContinue);
    setClassFilterArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const getLessonData = async () => {
    const result = await get(lessonContinue);
    setLessonsFilterArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const onChangeClass = (checkedValues) => {
    setCheckedClassValues(checkedValues);
  };

  const handleResetClass = () => {
    setCheckedClassValues([]);
  };

  const onChangeLessons = (checkedValues) => {
    setCheckedLessonValues(checkedValues);
  };

  const handleResetLessons = () => {
    setCheckedLessonValues([]);
  };

  useEffect(() => {
    getClassData();
    getLessonData();
  }, []);

  useEffect(() => {
    const selectedFilters = {
      class_id: checkedClassValues,
      lesson_id: checkedLessonValues
    };
    setQuery(selectedFilters);
  }, [checkedClassValues, checkedLessonValues, setQuery]);

  useEffect(() => {
    setCheckedClassValues([]);
    setCheckedLessonValues([]);
  },[key])

  return (
    <>
      <Card>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ textAlign: "left" }}>Sınıf Filtrele</span>
              <DownOutlined style={{ textAlign: "right" }} />
            </Button>
          </Col>
        </Row>
        <Row className="mt-20">
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChangeClass}
            value={checkedClassValues}
          >
            <Row>
              <Col>
                {classFilterArray?.map((item, i) => (
                  <Row key={i}>
                    <Checkbox value={item.value}>{item.label}</Checkbox>{" "}
                  </Row>
                ))}
              </Col>
            </Row>
          </Checkbox.Group>
        </Row>
        <Row gutter={24} className="mt-20">
          <Col span={24}>
            <Button
              onClick={handleResetClass}
              style={{
                width: "100%",
                backgroundColor: "#7365FE",
                color: "white",
              }}
            >
              Tüm Sınıflar
            </Button>
          </Col>
        </Row>
      </Card>

      <Card className="mt-30">
        <Row gutter={24}>
          <Col span={24}>
            <Button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ textAlign: "left" }}>Ders Filtrele</span>
              <DownOutlined style={{ textAlign: "right" }} />
            </Button>
          </Col>
        </Row>
        <Row className="mt-20">
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChangeLessons}
            value={checkedLessonValues}
          >
            <Row>
              <Col>
                {lessonsFilterArray?.map((item, i) => (
                  <Row key={i}>
                    <Checkbox value={item.value}>{item.label}</Checkbox>{" "}
                  </Row>
                ))}
              </Col>
            </Row>
          </Checkbox.Group>
        </Row>
        <Row gutter={24} className="mt-20">
          <Col span={24}>
            <Button
              onClick={handleResetLessons}
              style={{
                width: "100%",
                backgroundColor: "#7365FE",
                color: "white",
              }}
            >
              Tüm Dersler
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default GeneralFilter;
