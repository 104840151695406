import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { GetUserData } from "../../helper";

const Page = () => {

  const [url, setUrl] = useState(undefined);

  const getUser = async () => {
    const result = await GetUserData();
    setUrl(result.data.siha_url);
  };

  const redirectToURL = () => {
    const url1 = url;
    window.open(url1, '_blank');
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
    <Button onClick={redirectToURL}>Yönlendir</Button>
    </>
  );
};

export default Page;
