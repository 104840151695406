import React, { useEffect, useState } from "react";
import { GetUserData, get } from "../../helper";
import { Button, Col, Row, Spin } from "antd";
import AddAssignment from "./AddAssignment";
import AssignmentCard from "./AssignmentCard";
import { PlusOutlined } from "@ant-design/icons";
import Filter from "./Filter";

const Page = () => {

  const [type, setType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assignmentArray, setAssignmentArray] = useState([]);
  const [numberObject, setNumberObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(undefined);
  const [query, setQuery] = useState({});

  const getAssignments = async () => {
    setLoading(true);
    const result = await get("task/",null,query);
    const detail = result.data.detail;
    setNumberObject(result.data.totals);
    setAssignmentArray(result.data.detail);
    setLoading(false);
  };

  const getUser = async () => {
    const result = await GetUserData();
    setType(result.data.role);
    getAssignments();
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getAssignments();
  },[query])

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        type === "Genel Personel" && (
          <Row justify="space-between">
            <Col />
            <Col>
              <Button
                style={{
                  marginBottom: "5px",
                  backgroundColor: "#7365FE",
                  color: "white",
                }}
                icon={<PlusOutlined />}
                onClick={() => [setIsModalOpen(true), setInfo(undefined)]}
              >
                Yeni Görev Ekle
              </Button>
            </Col>
          </Row>
        )
      )}
      <Row gutter={24}>
        <Col span={6} className="mt-20">
          <Filter
            numberObject={numberObject}
            setQuery={setQuery}
          />
        </Col>
        <Col span={18}>
          <AssignmentCard
            assignmentArray={assignmentArray}
            getAssignments={getAssignments}
            setIsModalOpen={setIsModalOpen}
            setInfo={setInfo}
            type={type}
          />
        </Col>
      </Row>
      <AddAssignment
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getAssignments={getAssignments}
        info={info}
      />
    </div>
  );
};

export default Page;
