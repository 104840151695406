import { Card, Col, Row } from "antd";
import React from "react";
import NavigateButton from "../../../components/NavigateButton";
import man from "../../../assets/media/man.png";
import woman from "../../../assets/media/woman.png";

const BillCard = ({ paymentsArray }) => {
  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "3px" }}>Son Senetler</div>
          <div>
            <NavigateButton url={"/senetListeleri"} />
          </div>
        </div>
      }
    >
      {paymentsArray.length > 0 ? (
        paymentsArray.map((item, i) => (
          <Row gutter={24}>
            <Col span={18}>
              <Row gutter={24}>
                <Col span={4}>
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      style={{ borderRadius: "100%" }}
                      src={item.student_id.gender === "Erkek" ? man : woman}
                      alt="Profile"
                      width={40}
                      height={40}
                      preview={false}
                    />
                  </div>
                </Col>
                <Col span={20}>
                  <Row
                    style={{ fontWeight: 800 }}
                  >{`${item?.student_id?.first_name} ${item?.student_id?.last_name}`}</Row>
                  <Row style={{ color: "gray" }}>
                    {item?.parent_id?.first_name === undefined ||
                    item?.parent_id?.last_name === undefined
                      ? "Veli yok"
                      : `${item?.parent_id?.first_name} ${item?.parent_id?.last_name}`}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={6} className="mt-10" style={{ color: "green" }}>
              {new Intl.NumberFormat("tr-TR").format(item?.amount)} ₺
            </Col>
          </Row>
        ))
      ) : (
        <p>Şuan için senet bulunmamaktadır</p>
      )}
      {/* {paymentsArray.length > 0 && <NavigateButton url={"/senetListeleri"} />} */}
    </Card>
  );
};

export default BillCard;
