import { Button, Card, Col, Form, Image, Input, Modal, Row, Tag } from "antd";
import React from "react";
import { FilePdfOutlined } from "@ant-design/icons";

const ViewHomework = ({ setIsModalOpen, isModalOpen, selectedHomework }) => {
  return (
    selectedHomework && (
      <div className="homeworkViewModal">
        <Modal
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          centered
        >
          <p>
            {`${selectedHomework.class_id.name} / ${selectedHomework.section_id.name} Sınıfı`}{" "}
          </p>
          <hr />
          <p style={{ lineHeight: "2px", fontWeight: 700 }}>
            {selectedHomework.lesson_id.name}
          </p>
          <p style={{ lineHeight: "2px", color: "gray" }}>
            {selectedHomework.title}
          </p>
          <p style={{ lineHeight: "2px", color: "gray" }}>
            {selectedHomework.content}
          </p>
          {selectedHomework?.file[0] !== undefined && (
            <div
              className="mt-30"
              style={{ lineHeight: "2px", fontWeight: 700 }}
            >
              <p>Ödev İçeriği</p>
              {selectedHomework.file.map((item, index) => (
                <React.Fragment key={index}>
                  {item.split(".")[1] === "pdf" ? (
                    <Button
                      style={{
                        width: "100%",
                        padding: "20px 50px 70px 50px",
                        borderRadius: "10px",
                        backgroundColor:"#DCDCDC",
                        border:"none"
                      }}
                      onClick={() =>
                        window.open(
                          `https://api.sihaotomasyon.com${item}`,
                          "_blank"
                        )
                      }
                    >
                      <FilePdfOutlined style={{fontSize:"50px"}} />
                    </Button>
                  ) : (
                    <Image
                      style={{ width: "100%", marginTop: "10px" }}
                      src={`https://api.sihaotomasyon.com${item}`}
                      alt={`Resim ${index + 1}`}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}

          <hr />
          <Row gutter={24}>
            <Col span={20}>
              <Row gutter={24}>
                <Col span={10}>Ödevin Verildiği Tarih</Col>
                <Col span={1}>:</Col>
                <Col span={8}>
                  <Tag>{selectedHomework.date.split("T")[0]}</Tag>
                </Col>
              </Row>
              <Row gutter={24} className="mt-10">
                <Col span={10}>Ödev Teslim Tarih</Col>
                <Col span={1}>:</Col>
                <Col span={8}>
                  <Tag>{selectedHomework.end_date.split("T")[0]}</Tag>
                </Col>
              </Row>
            </Col>
            <Col span={4} className="mt-10">
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Kapat
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  );
};

export default ViewHomework;
