import { Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { CustomNumberInput, ModalFooter } from "../../../components";
import { formatResultMultipleName, get, post, put } from "../../../helper";
import { parentContinue, studentContinue } from "../../../services";

const AddParent = ({ getData, setIsModalOpen, isModalOpen, info }) => {
  const [form] = Form.useForm();

  const [studentArray, setStudentArray] = useState([]);

  const onFinish = async (values) => {
    const url = info ? `${parentContinue}${info._id}` : parentContinue;
    const result = await (info ? put(url, values) : post(url, values));
    if (result.status === 200) {
      getData();
      setIsModalOpen(false);
      message.success(
        info ? "Veli ekleme işlemi başarılı" : "Veli düzenleme işlemi başarılı"
      );
    } else message.error("İşlem Başarısız");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => setIsModalOpen(false);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearchStudentSelect = async (e) => {
    const inputLength = e.length;
    if (inputLength >= 3) {
      const result = await get(studentContinue, null, { keyword: e });
      setStudentArray(
        formatResultMultipleName(
          result.data.detail,
          "first_name",
          "last_name",
          "_id"
        )
      );
    } else
      setStudentArray([
        { label: "En az 3 harf girin", value: 1, disabled: true },
      ]);
  };

  useEffect(() => {
    if (info) {
      info.tckn = info.tckn.toString();
      info.children.map((item) => onSearchStudentSelect(item.first_name));
      form.setFieldsValue(info);
      form.setFieldsValue({ children: info.children.map((item) => item.id) });
    } else {
      setStudentArray([
        { label: "En az 3 harf girin", value: 1, disabled: true },
      ]);
      form.resetFields();
    }
  }, [info]);

  return (
    <Modal
      title={info ? "Veli Düzenle" : "Veli Kaydet"}
      open={isModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="İsim"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Soyisim"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Cinsiyet"
          name="gender"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <Select
            options={[
              {label:"Erkek",value:"Erkek"},
              {label:"Kadın",value:"Kadın"}
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Kimlik No"
          name="tckn"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
            },
            {
              max: 11,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
              min: 11,
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={11} />
        </Form.Item>

        {info && (
          <Form.Item
            label="Şifre"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}

        <Form.Item
          label="Telefon No"
          name="phone"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={10} prefix="+90" />
        </Form.Item>

        <Form.Item
          label="Öğrenci"
          name="children"
          rules={[
            {
              required: true,
              message: "Please input your section!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            optionFilterProp="children"
            options={studentArray}
            placeholder="Öğrencinin adını girin (en az 3 karakter)"
            filterOption={filterOption}
            onSearch={onSearchStudentSelect}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddParent;
