import React, { useEffect, useState } from "react";
import { GetUserData, get } from "../../helper";
import { Spin, Tabs } from "antd";
import StudentHomePage from "./StudentHomePage";
import TeacherHomePage from "./TeacherHomePage";
import GeneralHomePage from "./GeneralHomePage";
import ParentHomePage from "./ParentHomePage";
import ChildrenHomePage from "./ChildrenHomePage";

const Page = () => {
  const [type, setType] = useState(undefined);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [multipleChild, setMultipleChild] = useState(undefined);
  const [childrenArray, setChildrenArray] = useState([]);

  const getUser = async () => {
    setLoading(true);
    const response = await GetUserData();
    if (response.data.role === "Parent" && response.data.children.length > 1) {
      setMultipleChild(true);
      const result = await get("dashboard");
      if (
        result?.data?.children?.length > 0 &&
        result.data.children.length > 1
      ) {
        setChildrenArray(result.data.children);
        setType("Parent");
      }
      editTabs();
    } else setMultipleChild(false);
    setType(response.data.role);
    setLoading(false);
  };

  const editTabs = () => {
    setLoading(true);
    const arr = [];
    childrenArray.map((item, i) =>
      arr.push({
        key: i.toString(),
        tab: `${item.first_name} ${item.last_name}`,
        content: <ChildrenHomePage info={item} array={childrenArray} />,
      })
    );
    setItems(arr);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, [type]);

  return loading ? (
    <Spin size="large" />
  ) : (
    <>
      {type === "Student" && <StudentHomePage />}
      {type === "Teacher" && <TeacherHomePage />}
      {type === "Genel Personel" && <GeneralHomePage />}
      {type === "Parent" && multipleChild && (
        <Tabs defaultActiveKey="0" centered style={{marginTop:"-30px"}}>
          {items.map((item) => (
            <Tabs.TabPane key={item.key} tab={item.tab}>
              {item.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
      {(type === "Parent" && !multipleChild) && (<ParentHomePage />)}
    </>
  );
};

export default Page;
