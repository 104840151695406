import { Tag } from "antd";
import { ReportPage } from "./report";
import { RollcallPage } from "./rollCall";

export const items = [
  {
    key: "1",
    label: "Yoklama",
    children: <RollcallPage />,
  },
  {
    key: "2",
    label: "Rapor / İzin",
    children: <ReportPage />,
  },
];

export const columns = [
  {
    title: "Gün",
    dataIndex: "date",
    key: "date",
    align: "center",
  },
  {
    title: "Durum",
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (record) => (
      <Tag
        color={
          record === "Devamsız"
            ? "gold"
            : record === "Raporlu"
            ? "purple"
            : record === "İzinli"
            ? "green"
            : undefined
        }
        key={record}
      >
        {record}
      </Tag>
    ),
  },
];
