import { Card } from "antd";
import React from "react";
import Chart from 'react-apexcharts';

const COLORS = ["#002F49", "#EA5B0C", "#0097B2", "#FCBF4A"];

const PieChartComponent = ({ data }) => {
  const labels = data.map(item => item.name);
  const series = data.map(item => item.count);

  const options = {
    chart: {
      type: 'pie',
      width: '100%'
    },
    labels: labels,
    colors: COLORS,
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -30,
          formatter: function (value) {
            return value;
          }
        }
      }
    },
    legend: {
      position: 'bottom',
    }
  };

  return (
    <div className="pieCard">
      <Card style={{ textAlign: 'center'}}>
        <Chart options={options} series={series} type="pie" width="100%" height="260px" />
      </Card>
    </div>
  );
};

export default PieChartComponent;
