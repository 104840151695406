import React, { useState } from "react";
import { Alert, Button, Col, Form, Input, Row, message } from "antd";
import { get, post } from "../helper/HttpHelpers";
import { useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const LoginArea = ({ setLoginAvailable }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [errorAlert, setErrorAlert] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onCloseAlert = () => {
    setErrorAlert(undefined);
  };

  const onClick = async () => {
    setErrorAlert(undefined);
    setLoading(true);
    const formValue = form.getFieldsValue();

    try {
      const response = await post("/login", formValue);

      if (response.data.success) {
        const meResult = await get("me");
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(meResult));
        const { result } = response.data;
        message.success(`${result.first_name} Giriş Yaptı`);
        setLoginAvailable(false);
        navigate("/anaSayfa");
      } else {
        message.error("Bilgilerinizi kontrol ediniz");
      }
    } catch (error) {
      if (error.response) {
        console.error("Sunucu Hatası:", error.response.data);
      } else if (error.request) {
        console.error("İstek Hatası:", error.request);
        setErrorAlert(
          <Alert
            message="Hata Mesajı !"
            description="Sunucudan kaynaklı hata nedeniyle giriş yapamıyorsunuz lütfen daha sonra tekrar deneyin"
            type="info"
            closable
            onClose={onCloseAlert}
          />
        );
      } else {
        console.error("Hata:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {errorAlert}
      <Form name="basic" form={form}>
        <Row justify="center" align="middle">
          <Col xs={18} sm={15} lg={12} className="spaceLoginArea">
            <Form.Item name="tckn">
              <Input
                style={{ borderRadius: "20px", padding: "15px 10px" }}
                size="large"
                placeholder="Lütfen TC No Giriniz"
                prefix={<UserOutlined style={{ marginRight: "10px" }} />}
                onPressEnter={onClick}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle" className="spaceLoginArea">
          <Col xs={18} sm={15} lg={12}>
            <Form.Item name="password">
              <Input.Password
                style={{ borderRadius: "20px", padding: "15px 10px" }}
                size="large"
                placeholder="Lütfen Şifrenizi Giriniz"
                prefix={<LockOutlined style={{ marginRight: "10px" }} />}
                onPressEnter={onClick}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle" className="spaceLoginArea">
          <Col xs={{ span: 12, offset: 6 }} lg={{ span: 12, offset:6 }} >
            <Button
              className="loginBtn"
              style={{
                width: "50%",
                height: "50px",
                borderRadius: "20px",
                border: "1px solid #C0C0C0",
                backgroundColor: "white",
              }}
              onClick={onClick}
              loading={loading}
            >
              GİRİŞ YAP
            </Button>
          </Col>
        </Row> 
      </Form>
    </>
  );
};
export default LoginArea;
