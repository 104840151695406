import React from "react";
import { Card, Col, Row } from "antd";
import man from "../../../assets/media/man.png";
import woman from "../../../assets/media/woman.png";
import { IdcardOutlined, PhoneOutlined } from "@ant-design/icons";

const ProfileCard = ({ userInfo }) => {
  return (
    <div className="profileCard">
      <Card className="profileCardComp">
        <Row gutter={24}>
          <Col span={12} push={6}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                marginTop: "-10px",
              }}
            >
              <img
                style={{ borderRadius: "100%" }}
                src={userInfo?.gender === "Erkek" ? man : woman}
                alt="Profile"
                width={50}
                height={50}
                preview={false}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={18} push={3}>
            <p
              style={{ fontWeight: 700 }}
            >{`${userInfo?.first_name} ${userInfo?.last_name}`}</p>
            <p style={{ color: "white", opacity: 0.7 }}>
              {userInfo?.role
                ? userInfo?.role
                : `${userInfo?.class_id?.name} / ${userInfo?.section_id?.name}`}
            </p>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10} style={{ marginTop: "3px" }}>
            
            <IdcardOutlined />
            <p style={{ fontWeight: 700, fontSize: "10px" }}>
              {userInfo?.tckn}
            </p>
          </Col>
          <Col span={2}>
            <p style={{fontSize:"20px"}}>|</p>
          </Col>
          <Col span={10} style={{ marginTop: "3px" }}>
          <PhoneOutlined />
            <p style={{ fontWeight: 700, fontSize: "10px" }}>
              {userInfo?.phone}
            </p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProfileCard;
