import React, { useState } from "react";
import { Button, Card, Col, Popconfirm, Row, message } from "antd";
import {
  CalendarOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ViewHomework from "./ViewHomework";
import { delete_ } from "../../helper";

const AnnouncementCard = ({
  type,
  announcementArray,
  setIsAddModalOpen,
  setInfo,
  getMessage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHomework, setSelectedHomework] = useState();

  const formatTarih = (isoDateString) => {
    const tarih = new Date(isoDateString);
    const gun = tarih.getDate();
    const aylar = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    const ay = aylar[tarih.getMonth()];
    const yil = tarih.getFullYear();
    return `${gun} ${ay}`;
  };

  const onClickViewHomework = (item) => {
    setIsModalOpen(true);
    setSelectedHomework(item);
  };

  const deleteHomework = async (id) => {
    const result = await delete_(`message/${id}`);
    if (result.data.success) {
      getMessage();
      message.success("Ödev Başarıyla Silindi");
    } else message.error("Hata oluştu");
  };

  return (
    <>
      {announcementArray.map((item, i) => (
        <Card
          key={item.id}
          className={i === 0 ? undefined : "mt-10"}
          style={{ backgroundColor: i % 2 === 1 ? "#F8F8F8" : "white" }}
        >
          <Row gutter={24} justify="space-between" className="mt-10">
            <Col span={18}>
              <Row gutter={24}>
                <Col span={3} style={{ marginTop: "-10px" }}>
                  <Row style={{ color: "red", fontSize: "20px" }}>
                    {formatTarih(item.date).split(" ")[1]}
                  </Row>
                  <Row
                    style={{
                      marginLeft: "1px",
                      color: "red",
                      fontSize: "30px",
                    }}
                  >
                    {formatTarih(item.date).split(" ")[0]}
                  </Row>
                </Col>
                <Col style={{ fontSize: "30px" }} span={2}>
                  |
                </Col>
                <Col span={8}>
                  <Row gutter={24} className="mt-10">
                    <Col span={2}>
                      <CalendarOutlined
                        style={{ fontSize: "20px", marginTop: "5px" }}
                      />
                    </Col>
                    <Col
                      span={8}
                      style={{ fontSize: "12px", marginTop: "7px" }}
                    >
                      {new Date(item.date.split("T")[0]).toLocaleDateString(
                        "tr-TR"
                      )}
                    </Col>
                    <Col span={2} push={1} style={{ marginTop: "5px" }}>
                      -
                    </Col>
                    <Col
                      span={8}
                      style={{ fontSize: "12px", marginTop: "7px" }}
                    >
                      {new Date(item.end_date.split("T")[0]).toLocaleDateString(
                        "tr-TR"
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={2} style={{ fontSize: "30px" }}>
                  |
                </Col>
                <Col span={6}>
                  <Row>{item.title}</Row>
                  <Row>{item.content}</Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Button
                  style={{ backgroundColor: "#7365FF", color: "white" }}
                  onClick={() => onClickViewHomework(item)}
                >
                  Duyuruyu incele
                </Button>
              </Row>
              {type === "Teacher" && (
                <Row gutter={24} justify="center" align="middle">
                  <Col>
                    <Button
                      style={{
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "inherit",
                      }}
                      icon={<EditOutlined style={{ color: "green" }} />}
                      onClick={() => [setInfo(item), setIsAddModalOpen(true)]}
                    />

                    <Popconfirm
                      title="Duyuru silme işlemi"
                      description="Duyuruyu silmek istediğinizden emin misiniz ?"
                      onConfirm={() => deleteHomework(item._id)}
                      okText="Evet"
                      cancelText="Hayır"
                    >
                      <Button
                        style={{
                          border: "none",
                          boxShadow: "none",
                          backgroundColor: "inherit",
                        }}
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      ))}
      <ViewHomework
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        selectedHomework={selectedHomework}
      />
    </>
  );
};

export default AnnouncementCard;
