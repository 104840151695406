import React, { useState } from "react";
import { Card, Col, Row } from "antd";
import { ClassBranchPage } from "./ClassBranch";
import { StudentPage } from "./Student";
import { TeacherPage } from "./Teacher";
import { ParentPage } from "./Parent";
import { EmployeePage } from "./Employee";
import right from "../../assets/media/right.jpeg";
import studentIcon from "../../assets/media/ogrenci-icon.png";
import classBranchIcon from "../../assets/media/sinif-sube-icon.png";
import parentIcon from "../../assets/media/veli-icon.png";
import teacherIcon from "../../assets/media/ogretmen-icon.png";
import personnelIcon from "../../assets/media/genel-personel-icon.png";

const Page = () => {
  const [selectedCard, setSelectedCard] = useState("sınıfSube");
  const [type, setType] = useState("sınıfSube");

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId);
  };

  const cardInfo = [
    { label: "Sınıf / Şube", key: "sınıfSube", photo:classBranchIcon },
    { label: "Öğrenci", key: "ogrenci", photo:studentIcon },
    { label: "Öğretmen", key: "ogretmen", photo:teacherIcon },
    { label: "Veli", key: "veli", photo:parentIcon },
    { label: "Personel", key: "personel", photo:personnelIcon },
  ];

  return (
    <div className="recordsCards">
      <Row gutter={24}>
        {cardInfo.map((item) => (
          <Col style={{ width: "20%" }} key={item.id}>
            <div
              style={{
                position: "relative",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: 700,
                height:"90px",
                letterSpacing: "1px",
                padding: "10px 24px",
                borderRadius: "3px",
                boxShadow:
                  selectedCard === item.key
                    ? "15px 5px 10px rgba(0, 0, 0, 0.5)"
                    : "5px 5px 5px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                handleCardClick(item.key);
                setType(item.key);
              }}
            >
              {item.label}
              <img
                src={item.photo}
                alt="Fotoğraf"
                style={{
                  position: "absolute",
                  zIndex:2,
                  bottom: 15,
                  right: 20,
                  borderRadius:"0 0 10px 0",
                  width: "50px",
                  height: "50px",
                }}
              />
              <img
                src={right}
                alt="Fotoğraf"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  borderRadius:"0 0 10px 0",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
      {type === "sınıfSube" ? (
        <ClassBranchPage />
      ) : type === "ogrenci" ? (
        <StudentPage />
      ) : type === "ogretmen" ? (
        <TeacherPage />
      ) : type === "veli" ? (
        <ParentPage />
      ) : type === "personel" ? (
        <EmployeePage />
      ) : (
        <p>{type}</p>
      )}
    </div>
  );
};

export default Page;
