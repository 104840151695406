import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { delete_, get } from "../../../helper";
import FileDownload from "../../../helper/FileDownload";
import FileUpload from "../../../helper/FileUpload";
import { HowToComponent } from "../../../components";
import AddTeacher from "./AddTeacher";
import { teacherContinue } from "../../../services";

const Page = () => {
  const [form] = Form.useForm();

  const [teacherData, setTeacherData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(undefined);
  const [info, setInfo] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState({});
  const [filterView, setFilterView] = useState(true);

  const columns = [
    {
      title: "İsim",
      dataIndex: "first_name",
      key: "name",
      align: "center",
    },
    {
      title: "Soyisim",
      dataIndex: "last_name",
      key: "surname",
      align: "center",
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      key: "gender",
      align: "center",
    },
    {
      title: "Kimlik No",
      dataIndex: "tckn",
      key: "identityNo",
      align: "center",
    },
    {
      title: "Telefon No",
      dataIndex: "phone",
      key: "identityNo",
      align: "center",
    },
    {
      title: "Dersler",
      dataIndex: "lesson",
      key: "lesson",
      align: "center",
      render: (_, { lesson }) => (
        <>
          {lesson.map((les) => {
            return (
              <Tag color="orange" key={les._id}>
                {les.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "İşlemler",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editTeacher(record)} className="borderNone">
            <EditOutlined className="green" />
          </Button>
          <Popconfirm
            title="Öğretmen silme işlemi"
            description="Öğretmeni silmek istediğinizden emin misiniz ?"
            onConfirm={() => onConfirmDelete(record._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button className="borderNone">
              <DeleteOutlined className="red" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setInfo(undefined);
    setIsModalOpen(true);
  };

  const editTeacher = (record) => {
    setInfo(record);
    setIsModalOpen(true);
  };

  const onConfirmDelete = async (id) => {
    const result = await delete_(`${teacherContinue}${id}`);
    if (result.status === 200) {
      message.success("Öğretmen silme işlemi başarılı");
      getData();
    } else message.error("Öğretmen silme işlemi başarısız");
  };

  const getData = async (object) => {
    setLoading(true);
    const result = await get(teacherContinue, null, object);
    if (result.status === 200) {
      setTotalCount(result.data.total_count);
      result.data.detail.map((item, index) => (item.key = index));
      setTeacherData(result.data.detail);
      setLoading(false);
    } else message.error("Öğrenci bilgileri gelmedi");
  };

  const onChangeTable = (e) => {
    const object = {
      page: e.current,
      page_size: e.pageSize,
    };
    setQuery(object);
  };

  useEffect(() => {
    getData(query);
  }, [query]);

  const onChangeInput = (e) => {
    const keyword = e.target.value;
    getData({ keyword: keyword });
  };

  return (
    <>
      <Row justify="space-between" align="middle" className="mt-30">
        <Col style={{ marginRight: "5px" }}>
          <FileDownload model={"teacher"} />
        </Col>
        <Col style={{ marginRight: "5px" }}>
          <FileUpload type={"teacher"} getData={getData} />
        </Col>
        <Col>
          <FileDownload model={"teacher"} type={"export"} />
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          <HowToComponent />
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="mt-10"
        style={{ marginBottom: "10px" }}
      >
        <Col style={{ textAlign: "left" }}></Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            style={{
              backgroundColor: "#F5F5F5	",
              border: "none",
              color: "black",
              height: "40px",
              marginRight: "10px",
            }}
            size={"large"}
            icon={<FilterOutlined />}
            onClick={() => setFilterView((prev) => !prev)}
          />
          <Button
            style={{
              backgroundColor: "#7366F9",
              color: "white",
              borderRadius: "5px",
              height: "40px",
            }}
            icon={<PlusOutlined />}
            size={"large"}
            onClick={showModal}
          >
            Bireysel Öğretmen Ekle
          </Button>
          <AddTeacher
            getData={getData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            info={info}
          />
        </Col>
      </Row>
      {filterView && (
        <Form form={form} name="basic">
          <Form.Item label="Öğretmen Adı">
            <Row>
              <Input
                style={{ borderRadius: "20px" }}
                placeholder="Öğretmen adı giriniz"
                onChange={onChangeInput}
              />
            </Row>
          </Form.Item>
        </Form>
      )}
      <Row gutter={24} className="mt-30">
        <Col span={24}>
          <Table
            className="w-100"
            dataSource={teacherData}
            columns={columns}
            loading={loading}
            pagination={{
              // showSizeChanger: true,
              total: totalCount,
              showTotal: (total, range) => (
                <span className="text-muted">
                  <b>{totalCount}</b> kayıttan <b>{range[0]}</b> -{" "}
                  <b>{range[1]}</b> arası
                </span>
              ),
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
    </>
  );
};

export default Page;
