import { Button, Card, Col, Row, Tag } from "antd";
import React from "react";
import {
  CopyOutlined,
  StockOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const Filter = ({
  numberObject,
  setQuery,
}) => {

  const onClickCategory = (e) => {
    if (e === "all") setQuery({});
    else setQuery({ status: e });
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          {" "}
          <Button
            style={{
              width: "100%",
              backgroundColor: "#7365FE",
              textAlign: "left",
              fontSize: "17px",
              padding: "3px 10px 30px 10px",
            }}
            icon={
              <CheckCircleOutlined
                style={{ color: "black", fontSize: "17px" }}
              />
            }
          >
            Yapılacaklar
          </Button>
        </Col>
      </Row>
      <Row className="mt-20">
        <Button
          icon={
            <CopyOutlined
              style={{
                padding: "10px",
                borderRadius: "100%",
                backgroundColor: "#F2EFFF",
                color: "#9B8FE0",
              }}
            />
          }
          style={{ border: "none", boxShadow: "none" }}
          onClick={() => onClickCategory("all")}
        >
          Tüm İşler
        </Button>
      </Row>
      <Row gutter={24} className="mt-20" justify="space-between">
        <Col>
          <Button
            onClick={() => onClickCategory("done")}
            icon={
              <CheckCircleOutlined
                style={{
                  padding: "10px",
                  borderRadius: "100%",
                  backgroundColor: "#E4F6DF",
                  color: "#9EC89D",
                }}
              />
            }
            style={{ border: "none", boxShadow: "none" }}
          >
            Tamamlandı
          </Button>
        </Col>
        <Col className="mt-10">
          <Tag color="red" style={{ border: "none" }}>
            {numberObject?.done}
          </Tag>
        </Col>
      </Row>
      <Row gutter={24} className="mt-20" justify="space-between">
        <Col>
          <Button
            onClick={() => onClickCategory("given")}
            icon={
              <ClockCircleOutlined
                style={{
                  padding: "10px",
                  borderRadius: "100%",
                  backgroundColor: "#FDF7F5",
                  color: "#D19896",
                }}
              />
            }
            style={{ border: "none", boxShadow: "none" }}
          >
            Bekleyen
          </Button>
        </Col>
        <Col className="mt-10">
          <Tag color="red" style={{ border: "none" }}>
            {numberObject?.given}
          </Tag>
        </Col>
      </Row>
      <Row gutter={24} className="mt-20" justify="space-between">
        <Col>
          <Button
            onClick={() => onClickCategory("in_progress")}
            icon={
              <StockOutlined
                style={{
                  padding: "10px",
                  borderRadius: "100%",
                  backgroundColor: "#FFFCFD",
                  color: "#64BFD6",
                }}
              />
            }
            style={{ border: "none", boxShadow: "none" }}
          >
            Yapılıyor
          </Button>
        </Col>
        <Col className="mt-10">
          <Tag color="red" style={{ border: "none" }}>
            {numberObject?.in_progress}
          </Tag>
        </Col>
      </Row>
      <Row gutter={24} className="mt-20" justify="space-between">
        <Col span={5}>
          <Button
            // onClick={() => onClickCategory("in_progress")}
            icon={
              <DeleteOutlined
                style={{
                  padding: "10px",
                  borderRadius: "100%",
                  backgroundColor: "#FDF5F8",
                  color: "#D98181",
                }}
              />
            }
            style={{ border: "none", boxShadow: "none" }}
          >
            Çöp
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
