import { Button } from "antd";
import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";

const TeacherAddHomework = ({ setIsAddModalOpen, pathKey }) => {
  useEffect(() => {}, [pathKey]);

  return (
    <Button
      style={{
        marginBottom: "5px",
        backgroundColor: "#7365FE",
        color: "white",
      }}
      icon={<PlusOutlined />}
      onClick={() => setIsAddModalOpen(true)}
    >
      {pathKey === "odevler" ? "Yeni Ödev Ekle" : "Yeni Duyuru Ekle"}
    </Button>
  );
};

export default TeacherAddHomework;
