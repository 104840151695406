import React from "react";
import { Button } from "antd";
import { getBlob } from "../helper";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";

const FileDownload = ({ model , type="template", queryDownload=undefined }) => {

  const onClickDownload = async () => {
    const result = await getBlob(`${model}/excel/${type}`,null,queryDownload);
    saveAs(result.data, type === "template" ? "Şablon.xlsx" : "Liste.xlsx");
  };

  return (
    <Button
      style={{ 
        backgroundColor: "#7366F9",
        color: "white",
        borderRadius: "5px",
      }}
      size={"large"}
      icon={<CloudDownloadOutlined />}
      block
      onClick={onClickDownload}
    >
      {type === "template" ? "Excel Şablonu İndir" : "Excel Listesi İndir"}
    </Button>
  );
};

export default FileDownload;