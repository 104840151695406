import React from "react";
import { Button } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const ModalFooter = ({ form, handleCancel }) => {
  return [
    <Button
      className="closeBtnModal"
      onClick={handleCancel}
      icon={<CloseOutlined />}
    >
      Kapat
    </Button>,
    <Button
      className="saveBtnModal"
      icon={<CheckOutlined />}
      onClick={() => form.submit()}
    >
      Kaydet
    </Button>,
  ];
};

export default ModalFooter;
