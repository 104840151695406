import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import Chart from "react-apexcharts";
import maleFemalePng from "../../../assets/media/maleFemale.png";

const GenderChart = ({ data }) => {
  const [hovering, setHovering] = useState(false);

  let total = 0;
  data?.map((item) => (total = total + item?.count));

  const options = {
    chart: {
      height: 280,
      type: "radialBar",
    },
    series: data.map((item) => (item?.count * 100) / total),
    labels: data.map((item) => item?.name),
    colors: ["rgba(234, 90, 13, 1)", "rgba(9, 41, 73, 1)"], // Renkleri buraya ekleyin
  };

  useEffect(() => {}, []);

  return (
    <div className="genderCardComp">
      <Card>
        <div
          className="chart-container"
          style={{ position: "relative" }}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <img src={maleFemalePng} alt="Your Image" className="chart-overlay" />
          <Chart
            options={options}
            series={options.series}
            type="radialBar"
            height={250}
            className="chart"
          />
        </div>
        <Row gutter={24} style={{ marginTop: "-25px" }}>
          <Col span={12} style={{color:"rgba(234, 90, 13, 1)"}}>Kadın</Col>
          <Col span={12} style={{color:"rgba(9, 41, 73, 1)"}}>Erkek</Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{color:"rgba(234, 90, 13, 1)"}}>{data.at(0)?.count}</Col>
          <Col span={12} style={{color:"rgba(9, 41, 73, 1)"}} >{data.at(1)?.count}</Col>
        </Row>
      </Card>
    </div>
  );
};

export default GenderChart;
