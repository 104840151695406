import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Filter = ({ type, lessonsFilterArray, setQuery, key }) => {
  const [checkedLessonValues, setCheckedLessonValues] = useState([]);

  const onChange = (checkedValues) => {
    setCheckedLessonValues(checkedValues[0]);
  };

  const handleReset = () => {
    setCheckedLessonValues([]);
  };

  useEffect(() => {
    const selectedFilters = {
      lesson_id: checkedLessonValues,
    };
    setQuery(selectedFilters);
  }, [checkedLessonValues, setQuery]);

  useEffect(() => {
    setCheckedLessonValues([]);
  }, [key]);

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ textAlign: "left" }}>Filtrele</span>
            <DownOutlined style={{ textAlign: "right" }} />
          </Button>
        </Col>
      </Row>
      <Row className="mt-20">
        <Checkbox.Group
          style={{
            width: "100%",
          }}
          onChange={onChange}
          value={checkedLessonValues}
        >
          <Row>
            <Col>
              {lessonsFilterArray?.map((item, i) => (
                <Row key={i}>
                  <Checkbox value={type === "Teacher" ? item.value : item.id}>
                    {type === "Teacher" ? item.label : item.name}
                  </Checkbox>{" "}
                </Row>
              ))}
            </Col>
          </Row>
        </Checkbox.Group>
      </Row>
      <Row gutter={24} className="mt-20">
        <Col span={24}>
          <Button
            onClick={handleReset}
            style={{
              width: "100%",
              backgroundColor: "#7365FE",
              color: "white",
            }}
          >
            {type === "Teacher" ? "Tüm Dersler" : "Tüm Ödevler"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
