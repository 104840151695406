import React from 'react';
import Chart from "react-apexcharts";

class SplineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'basic-spline'
        },
        xaxis: {
          categories: this.props.data.map(item => item.month)
        },
        fill: {
          colors: ['#7365FE'], // Alan rengi
          type: 'gradient', // Alanın tipi (solid, gradient)
          gradient: {
            shade: 'light', // Gölgelendirme
            type: "vertical", // Dikey gradient
            shadeIntensity: 0.5,
            gradientToColors: undefined, // İkinci renk
            inverseColors: true,
            opacityFrom: 0.8, // Opaklık
            opacityTo: 0.1,
            stops: [0, 100]
          }
        },
        stroke: {
            curve: 'smooth', // Eğri tipi
            colors: ['#7365FE'] // Çizgi rengi
          }
      },
      series: [{
        name: 'Puan',
        data: this.props.data.map(item => item.score)
      }]
    }
  }

  render() {
    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="area" height={300} />
      </div>
    );
  }
}

export default SplineChart;
