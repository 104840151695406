import { Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { CustomNumberInput, ModalFooter } from "../../../components";
import { formatResultWith, get, post, put } from "../../../helper";
import { classContinue, studentContinue } from "../../../services";

const AddStudent = ({ getData, setIsModalOpen, isModalOpen, info }) => {
  const [form] = Form.useForm();

  const [sectionArray, setSectionArray] = useState([]);
  const [classArray, setClassArray] = useState([]);
  const [data, setData] = useState([]);

  const onFinish = async (values) => {
    const url = info ? `${studentContinue}${info._id}` : studentContinue;
    const result = await (info ? put(url, values) : post(url, values));
    if (result.status === 200) {
      getData();
      setIsModalOpen(false);
      message.success(
        info
          ? "Öğrenci düzenleme işlemi başarılı"
          : "Öğrenci ekleme işlemi başarılı"
      );
    } else message.error("İşlem Başarısız");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => setIsModalOpen(false);

  const getClassData = async () => {
    const result = await get(classContinue);
    setData(result.data.detail);
    setClassArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const onChangeClass = (e) => {
    const arr = data.find((item) => item._id === e);
    setSectionArray(formatResultWith(arr?.sections, "name", "id"));
  };

  useEffect(() => {
    getClassData();
  }, []);

  useEffect(() => {
    if (info) {
      info.tckn = info.tckn.toString();
      onChangeClass(info.class_id);
      form.setFieldsValue(info);
    } else form.resetFields();
  }, [info]);

  return (
    <Modal
      title={info ? "Öğrenci Düzenle" : "Öğrenci Kaydet"}
      open={isModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={<ModalFooter form={form} handleCancel={handleCancel} />}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="İsim"
          name="first_name"
          rules={[
            {
              message: "Lütfen isim giriniz!",
              required: true,
            },
            {
              message: "Lütfen isim giriniz!",
              whitespace: true,
            },
            {
              max: 20,
              message: "Adınız en az 2 en fazla 20 karakter olmalıdır!",
              min: 2,
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Soyisim"
          name="last_name"
          rules={[
            {
              message: "Lütfen soyisim giriniz!",
              required: true,
            },
            {
              message: "Lütfen soyisim giriniz!",
              whitespace: true,
            },
            {
              max: 20,
              message: "Soyadınız en az 2 en fazla 20 karakter olmalıdır!",
              min: 2,
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Cinsiyet"
          name="gender"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <Select
            options={[
              { label: "Erkek", value: "Erkek" },
              { label: "Kadın", value: "Kadın" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Sınıf"
          name="class_id"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            options={classArray}
            onChange={onChangeClass}
          />
        </Form.Item>

        <Form.Item
          label="Şube"
          name="section_id"
          rules={[
            {
              required: true,
              message: "Please input your section!",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="children"
            options={sectionArray}
          />
        </Form.Item>

        <Form.Item
          label="Öğrenci No"
          name="student_no"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Kimlik No"
          name="tckn"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
            },
            {
              max: 11,
              message: "Lütfen geçerli bir kimlik numarası giriniz!",
              min: 11,
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={11} />
        </Form.Item>

        {info && (
          <Form.Item
            label="Şifre"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}

        <Form.Item
          label="Telefon No"
          name="phone"
          rules={[
            {
              required: true,
              message: "Lütfen geçerli bir telefon numarası giriniz!",
            },
          ]}
        >
          <CustomNumberInput digitsOfNumber={10} prefix="+90" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddStudent;
