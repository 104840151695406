import React, { useEffect, useState } from "react";
import { Spin, Tabs } from "antd";
import StudentTimetablePage from "./StudentTimetablePage";

const { TabPane } = Tabs;

const TimetableWithoutEdit = ({ type, studentsInfo }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [multipleChild, setMultipleChild] = useState(false);

  const editTabs = () => {
    const arr = [];
    studentsInfo.forEach((item, i) =>
      arr.push(
        <TabPane key={i} tab={`${item.first_name} ${item.last_name}`}>
          <StudentTimetablePage info={item} />
        </TabPane>
      )
    );
    setItems(arr);
  };

  const setData = () => {
    if (studentsInfo?.length > 0) {
      if (studentsInfo?.length > 1) {
        setMultipleChild(true);
        editTabs();
      } else setMultipleChild(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setData();
  }, []);

  return loading ? (
    <Spin />
  ) : multipleChild ? (
    <Tabs defaultActiveKey="0" centered>
      {items}
    </Tabs>
  ) : (
    <>
      <StudentTimetablePage info={studentsInfo} />
    </>
  );
};

export default TimetableWithoutEdit;
