import { Input } from "antd";
import React from "react";

const CustomBillInput = ({
  value = "",
  onChange,
  digitsOfNumber,
  placeholder,
  prefix,
  disabled=false
}) => {
  const triggerChange = (changedValue) => onChange?.(changedValue);

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const onNumberChange = ({ target }) => {
    const numberInput = target?.value
      .replace(/\D/g, "")
      .slice(0, digitsOfNumber);
    var formattedNumber = formatNumberWithCommas(numberInput);
    triggerChange(formattedNumber);
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={onNumberChange}
      placeholder={placeholder}
      prefix={prefix}
      disabled={disabled}
    />
  );
};
export default CustomBillInput;
