import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import { classContinue, studentContinue } from "../../../services";
import { GetUserData, formatResultWith, get, put } from "../../../helper";
import { columns } from "./Constant";
import { PlayCircleOutlined, FilterOutlined } from "@ant-design/icons";

const Page = () => {
  const [form] = Form.useForm();

  const [sectionArray, setSectionArray] = useState([]);
  const [classArray, setClassArray] = useState([]);
  const [classValue, setClassValue] = useState();
  const [branchValue, setBranchValue] = useState();
  const [classSectionData, setClassSectionData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rollCallList, setRollCallList] = useState();
  const [rollCallInfo, setRollCallInfo] = useState();
  const [teacherView, setTeacherView] = useState(undefined);
  const [classInfo, setClassInfo] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [filterView, setFilterView] = useState(true);

  const getClassData = async () => {
    const result = await get(`${classContinue}`);
    setClassSectionData(result.data.detail);
    setClassArray(formatResultWith(result.data.detail, "name", "_id"));
  };

  const onChangeClass = (e) => {
    setClassValue(e);
    setTableData([]);
    form.setFieldsValue({ section_id: undefined });
    const sections = classSectionData.find((item) => item._id === e);
    const arr = [];
    sections?.sections.map((item) =>
      arr.push({ label: item.name, value: item.id })
    );
    setSectionArray(arr);
  };

  const onChangeBranch = (e) => {
    setBranchValue(e);
    getStudentData({
      page_size: 100,
      class_id: classValue,
      section_id: e,
    });
  };

  const getStudentData = async (e) => {
    const resultContinue = await get(studentContinue, null, e);
    const detail = resultContinue.data.detail;
    detail.map((item, index) => (item.key = index));
    setTableData(detail);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const arr = [];
      selectedRows.map((item) => arr.push(item._id));
      setRollCallList(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.absence_status,
      name: record?.name,
    }),
  };

  const onChangeInput = (e) => {
    getStudentData({
      page_size: 100,
      class_id: classValue,
      section_id: branchValue,
      keyword: e.target.value,
    });
  };

  const confirmPopconfirm = async () => {
    const arr = [];
    rollCallList.map((item) => arr.push(item._id));
    const result = await put("student/absence/", arr);
    if (result.data.success) {
      message.success("Yoklama başarıyla alındı");
      getStudentData({
        page_size: 100,
        class_id: classValue,
        section_id: branchValue,
      });
    } else {
      message.error("Yoklama alınırken hata oluştu");
      getStudentData({
        page_size: 100,
        class_id: classValue,
        section_id: branchValue,
      });
    }
  };

  const handleClick = () => {
    if (rollCallList && rollCallList.length > 0) {
      setRollCallInfo(
        `Derse katılım sağlamayanlar => ${rollCallList?.map(
          (item) => `${item.first_name} ${item.last_name}`
        )}`
      );
    } else setRollCallInfo("Derse katılım tamdır");
  };

  const cancelPopconfirm = () => {
    console.log("Cancel");
  };

  const teacherLesson = async () => {
    setLoading(true);
    const result = await get("teacher/current-lesson");
    setClassInfo({
      class: result?.data?.detail?.class_id?.name,
      section: result?.data?.detail?.section_id?.name,
      lesson: result?.data?.detail?.lesson?.name,
    });
    getStudentData({
      class_id: result?.data?.detail?.class_id?._id,
      section_id: result?.data?.detail?.section_id?._id,
    });
    if (!result.data.success) setTeacherView(false);
    else setTeacherView(true);
    setLoading(false);
  };

  const getUser = async () => {
    setLoading(true);
    const result = await GetUserData();
    if (result.data.role === "Teacher") teacherLesson();
    else {
      setTeacherView(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
    getClassData();
  }, []);

  useEffect(() => {}, [tableData]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : teacherView !== undefined ? (
        teacherView ? (
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            autoComplete="off"
          >
            <Row
              justify="space-between"
              className="mt-10"
              style={{ marginBottom: "10px" }}
            >
              <Col />
              <Col>
                <Button
                  style={{
                    backgroundColor: "#F5F5F5	",
                    border: "none",
                    color: "black",
                    height: "40px",
                    marginRight: "10px",
                  }}
                  size={"large"}
                  icon={<FilterOutlined />}
                  onClick={() => setFilterView((prev) => !prev)}
                />
                <Button
                  style={{
                    backgroundColor: "#7366F9",
                    color: "white",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  icon={<PlayCircleOutlined />}
                  size={"large"}
                >
                  Nasıl Yapılır ?
                </Button>
              </Col>
            </Row>
            {filterView &&
              (classInfo === undefined ? (
                <Row gutter={24} className="mt-10">
                  <Col span={8}>
                    <Form.Item label="Sınıf" name="class_id">
                      <Select
                        style={{ textAlign: "left", borderRadius: "20px" }}
                        showSearch
                        allowClear
                        placeholder="Sınıf seçiniz"
                        options={classArray}
                        onChange={onChangeClass}
                        optionFilterProp="children"
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Şube" name="section_id">
                      <Select
                        style={{ textAlign: "left", borderRadius: "20px" }}
                        showSearch
                        allowClear
                        placeholder="Şube seçiniz"
                        optionFilterProp="children"
                        options={sectionArray}
                        onChange={onChangeBranch}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Öğrenci Adı" name="studentName">
                      <Input
                        style={{ textAlign: "left", borderRadius: "20px" }}
                        placeholder="Öğrenci adı ile arama..."
                        onChange={onChangeInput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <>
                  <p>{`${classInfo?.lesson}`}</p>
                  <p>{`${classInfo?.class} / ${classInfo?.section} Sınıf Listesi`}</p>
                </>
              ))}
            <>
              <Row gutter={24}>
                <Col span={24}>
                  <Table
                    style={{ width: "100%" }}
                    dataSource={tableData.length > 0 ? tableData : undefined}
                    columns={columns}
                    rowSelection={rowSelection}
                    pagination={false}
                  />
                </Col>
              </Row>
              <Row justify="center" align="center" className="mt-30">
                <Col>
                  <Popconfirm
                    title="Yoklama Al"
                    description={rollCallInfo}
                    onConfirm={confirmPopconfirm}
                    onCancel={cancelPopconfirm}
                    okText="Kaydet"
                    cancelText="Vazgeç"
                  >
                    <Button
                      onClick={handleClick}
                      style={{
                        marginRight: "15px",
                        backgroundColor: "#7365FF",
                        color: "white",
                      }}
                    >
                      Yoklama Al
                    </Button>
                  </Popconfirm>
                  <Space>
                    <Button
                      style={{ backgroundColor: "#7365FF", color: "white" }}
                    >
                      Geç Kaldı
                    </Button>
                  </Space>
                </Col>
              </Row>
            </>
          </Form>
        ) : (
          <p>Şuan için yoklama alınacak sınıfınız bulunmamaktadır</p>
        )
      ) : null}
    </div>
  );
};

export default Page;
